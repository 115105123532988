/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React from 'react';

import AlertsMenu from '../Menu/AlertsMenu';
import InstallMenu from '../Menu/InstallMenu';
import ManageMenu from '../Menu/ManageMenu';
import MobileMenu from '../Menu/MobileMenu';
import ReportForDates from '../TableActions/ReportForDates';
import SearchItem from '../TableMixin/SearchItem';
import './TableMixin.scss';

const _ = require('lodash');

const oprFields = [
  { field: 'Intensity', opr: 'IntensityOpr' },
  { field: 'Probability', opr: 'ProbabilityOpr' },
  { field: 'DetectedAt', opr: 'DetectedAtOpr' },
  { field: 'gPSstatusRatio', opr: 'gPSstatusRatioOpr' },
  { field: 'CommunicationOKratio', opr: 'CommunicationOKratioOpr' },
];
const datesFields = [
  'DetectedAt',
  'fixDate',
  'ClosureDate',
  'SensorInstallationDate',
  'InsertDate',
  'CreateDate',
  'CreationDate',
  'create_date',
];

const TableMixin = (InnerComponent, SettingsComponent) =>
  class extends React.Component {
    constructor(props) {
      super(props);
      this.toggleSettingsDisplay = this.toggleSettingsDisplay.bind(this);
      this.getStateFromProps = this.getStateFromProps.bind(this);
      this.rowClassNameGetter = this.rowClassNameGetter.bind(this);
      this.onRowClick = this.onRowClick.bind(this);
      this.renderFilters = this.renderFilters.bind(this);
      this.sortTable = this.sortTable.bind(this);
      this.sortTableLocaly = this.sortTableLocaly.bind(this);
      this.processFilters = this.processFilters.bind(this);
      this.cleanEmptyFilters = this.cleanEmptyFilters.bind(this);
      this.filterItems = this.filterItems.bind(this);
      this.state = Object.assign(this.getStateFromProps(props), {
        showSettings: false,
        goToRow: null,
        searchText: '',
      });
    }

    cleanEmptyFilters(filters) {
      return filters.filter((filter) => {
        if (filter.op) {
          // For operator fields
          if (filter.op === 'range') {
            // For date fields, check if 'from' or 'to' is provided
            return Boolean(filter.from) || Boolean(filter.to);
          } else {
            // For other operator fields, check if 'value' is non-empty and contains non-empty strings
            return (
              Array.isArray(filter.value) &&
              filter.value.length > 0 &&
              filter.value.some((val) => val && val.toString().trim().length > 0)
            );
          }
        } else {
          // For regular fields, check if 'value' is non-empty and contains non-empty strings
          return (
            Array.isArray(filter.value) &&
            filter.value.length > 0 &&
            filter.value.some((val) => val && val.toString().trim().length > 0)
          );
        }
      });
    }

    processFilters(valuesCopy) {
      let filters = [];
      let defFilters = {};
      let sort = { field: valuesCopy['sortBy'], dir: valuesCopy['sortByDir'] };
      if (valuesCopy == null) {
        return { filters, defFilters, sort };
      }
      defFilters['sortBy'] = valuesCopy['sortBy'];
      defFilters['sortByDir'] = valuesCopy['sortByDir'];
      delete valuesCopy['sortBy'];
      delete valuesCopy['sortByDir'];

      datesFields.forEach((field) => {
        var filter = { empty: true, to: false, from: false };
        var toKey = field + '-To';
        var fromKey = field + '-From';
        const toValue = valuesCopy[toKey];
        const fromValue = valuesCopy[fromKey];

        if (toKey in valuesCopy && toValue) {
          filter.to = valuesCopy[toKey];
          filter.empty = false;
          defFilters[toKey] = toValue;
          delete valuesCopy[toKey];
        }
        if (fromKey in valuesCopy && fromValue) {
          filter.from = valuesCopy[fromKey];
          filter.empty = false;
          defFilters[fromKey] = fromValue;
          delete valuesCopy[fromKey];
        }
        if (!filter.empty) {
          delete filter.empty;
          filter.op = 'range';
          filter.field = field;
          filters.push(filter);
        }
      });

      oprFields.forEach((fieldData) => {
        if (fieldData.opr in valuesCopy) {
          var opValue = valuesCopy[fieldData.opr];
          if (typeof opValue === 'object') {
            opValue = opValue.value;
          }
          if (fieldData.field in valuesCopy) {
            const filterObj = {
              field: fieldData.field,
              value: [valuesCopy[fieldData.field]],
              op: opValue,
            };
            filters.push(filterObj);
            defFilters[fieldData.field] = valuesCopy[fieldData.field];
            delete valuesCopy[fieldData.field];
          }
          delete valuesCopy[fieldData.opr];
          defFilters[fieldData.opr] = opValue;
        }
      });

      Object.keys(valuesCopy).forEach((key) => {
        const values = valuesCopy[key];
        if (values) {
          const filterObj = { field: key, value: values };
          if (key === 'LastUninstallReason') {
            filterObj.avoidNulls = true;
          }
          filters.push(filterObj);
          defFilters[key] = values;
        }
      });
      filters = this.cleanEmptyFilters(filters);

      return { filters, defFilters, sort };
    }

    toggleSettingsDisplay() {
      this.setState({ showSettings: !this.state.showSettings });
    }

    rowClassNameGetter(rowIndex) {
      const {
        selectedFeature,
        indexMap,
        displayAssessments,
        selectedAssessment,
        path,
      } = this.props;
      const feature = displayAssessments ? selectedAssessment : selectedFeature;
      let className = '';

      switch (path) {
        case 'alerts':
          if (this.props.alertsContextMenu === 'noise') {
            if (
              feature !== '' &&
              rowIndex !== '' &&
              indexMap &&
              rowIndex == indexMap[feature.ID]
            ) {
              className = 'active-row';
            }
          } else {
            if (
              feature !== '' &&
              rowIndex !== '' &&
              rowIndex == indexMap[feature]
            ) {
              className = 'active-row';
            } else if (this.props.alertsContextMenu === 'alerts') {
              const itrAlert = this.props.items[rowIndex];
              if (itrAlert && itrAlert.Burst === 2) {
                className = 'pink-row';
              }
            }
          }
          break;
        case 'couples': {
          const coupleItem = this.props.items[rowIndex];
          if (
            feature !== '' &&
            rowIndex !== '' &&
            indexMap &&
            rowIndex == indexMap[feature]
          ) {
            className = 'active-row';
          }
          if (coupleItem && coupleItem.MissingDevices) {
            className += ' deleted';
          }

          break;
        }
        case 'manage':
          const { manageItem } = this.props;
          if (manageItem != null && indexMap[manageItem.id] == rowIndex) {
            className = 'active-row';
          }
          break;

        case 'mobile':
          if (
            feature &&
            rowIndex !== '' &&
            indexMap &&
            rowIndex == indexMap[feature.alert_id]
          ) {
            className = 'active-row';
          }

          break;

        default:
          if (
            feature !== '' &&
            rowIndex !== '' &&
            indexMap &&
            rowIndex == indexMap[feature]
          ) {
            className = 'active-row';
          }
          break;
      }

      return className;
    }

    onRowClick(event, rowIndex, onFailCallBack, additionalData) {
      if (event === 'sensorLink') {
        this.props.onItemClick(rowIndex, onFailCallBack);
      } else if (this.props.path !== 'manage') {

        const feature = this.props.items[rowIndex];

        this.props.onItemClick(feature, onFailCallBack, additionalData);
      } else {
        const feature = this.filterItems()[rowIndex];
        this.props.onItemClick(feature, onFailCallBack, additionalData);
      }
    }

    removeFilter(filter) {
      this.props.removeFilter(filter);
    }

    renderFilters(props) {
      const {
        path,
        alertsContextMenu,
        displayAssessments,
        installContext,
        filters,
        t,
        mobileTasksFilters,
        mobileAlertsFilters,
        mobileDisplayMode
      } = props;
      let newFilters;
      switch (path) {
        case 'alerts':
          if (alertsContextMenu === 'noise') {
            newFilters = filters.noiseAlerts.filters;
          } else if (alertsContextMenu === 'valve') {
            newFilters = props.valveAlertsFilters;
          } else {
            newFilters = filters.filters;
          }
          break;
        case 'sensors':
          newFilters = [...filters.sensorsFilters, ...filters.sops.filters];
          break;
        case 'couples':
          if (displayAssessments == false) {
            newFilters = filters.couples.filters;
          }
          break;
        case 'install':
          if (installContext == 'SOPs') {
            newFilters = filters.sops.filters;
          } else if (installContext == 'Sensors') {
            newFilters = [...filters.sensorsFilters, ...filters.sops.filters];
          }
          break;
        case 'mobile':
          switch (mobileDisplayMode) {
            case 'Tasks':
              newFilters = mobileTasksFilters.filters;
              break;
            case 'Alerts':
              newFilters = mobileAlertsFilters.filters;
              break;
          }
        default:
          break;
      }

      let output = dispatchEvent ? null : (
        <span className='filter'>{t('no_filters_applyed')}</span>
      );

      const filterNames = {
        AlertState: t('status'),
        // alert_state: t('status'),
        Priority: t('priority'),
        AlertType: t('type'),
        IntensityOpr: t('intensity_opr'),
        Intensity: t('intensity'),
        ProbabilityOpr: t('probability_opr'),
        WorkArea: t('work_area'),
        RepairCode: t('repair_code'),
        ContractorID: t('contractor_id'),
        DetectedAt: t('detected_at'),
      };

      if (newFilters && newFilters.length) {
        newFilters = newFilters.filter(function (filter) {
          if (filter.value && filter.value.length === 0) {
            return false;
          } else if (filter.field === 'sortBy') {
            return false;
          }
          return true;
        });

        output = newFilters.map((filter, index) => (
          <span className='filter' key={index}>
            {filterNames[filter.field] || t(filter.field)}
            <span
              className='remove-filter'
              onClick={() => this.removeFilter(filter.field)}
            >
              {t('x')}
            </span>
          </span>
        ));
      }
      return output;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.setState(this.getStateFromProps(nextProps));
    }

    shouldComponentUpdate(nextProps, nextState) {
      return true;
    }

    componentDidMount() {
      const { selectedFeature, alertsContextMenu, path } =
        this.props;
      if (!selectedFeature) {
        return;
      }
      const selectedRow = this.props.items.filter(function (item) {

        // if (alertsContextMenu === 'alerts') {
        if (path === 'mobile') {
          // console.log('item.alert_id case mobile', item.alert_id);
          return item.alert_id === selectedFeature?.alert_id;
        } else {
          // console.log('item.id', item.id);
          return item.id === selectedFeature;
        }
      })[0];
      setTimeout(() => {
        this.setState({
          goToRow: this.props.items.indexOf(selectedRow),
        });
      }, 500);
    }

    componentDidUpdate(prevProps) {

      const { selectedFeature, showDetails, items, path, installMode, alertsContextMenu } =
        this.props;

      // if (selectedFeature) {
      //   console.log('selectedFeature componentDidUpdate', selectedFeature);
      // }

      switch (path) {
        case 'manage':
          break;
        case 'mobile':
          // console.log('alertsContextMenu componentDidUpdate', alertsContextMenu);

          if (selectedFeature) {
            if (
              selectedFeature !== prevProps.selectedFeature ||
              showDetails !== prevProps.showDetails ||
              items.length !== prevProps.items.length ||
              path !== prevProps.path ||
              (installMode &&
                installMode.installContext !==
                prevProps.installMode.installContext)
            ) {
              // console.log('this.props.items in mobile', this.props.items);

              const selectedRow = items.filter(function (item) {
                return item.alert_id == selectedFeature?.alert_id;
              })[0];
              if (selectedRow) {
                // console.log('selectedRow comp update mobile', selectedRow);
              }
              setTimeout(() => {
                // console.log('go to row in mobile', selectedRow);
                // console.log('items.indexOf(selectedRow)', items.indexOf(selectedRow));
                this.setState({
                  goToRow: items.indexOf(selectedRow),
                });
              }, 500);
            }
          }
          break;
        default:
          if (
            selectedFeature !== prevProps.selectedFeature ||
            showDetails !== prevProps.showDetails ||
            items.length !== prevProps.items.length ||
            path !== prevProps.path ||
            (installMode &&
              installMode.installContext !==
              prevProps.installMode.installContext)
          ) {
            const selectedRow = this.props.items.filter(function (item) {
              // console.log('item.id == selectedFeature in default', item.id == selectedFeature);
              return item.id == selectedFeature;
            })[0];
            if (selectedRow) {
              // console.log('selectedRow comp update default', selectedRow);
            }
            setTimeout(() => {
              // console.log('items.indexOf(selectedRow) default', items.indexOf(selectedRow));

              this.setState({
                goToRow: this.props.items.indexOf(selectedRow),
              });
            }, 500);
          }
          break;
      }
    }

    sortTable(field, dir) {
      this.props.sortTable(field, dir);
    }

    sortTableLocaly(field, dir) {
      this.props.sortTableLocaly(field, dir);
      //const { items } = this.props;
      //items.sort((a, b) => {
      //    let bRc = a[field] > b[field] ? 1 : (b[field] > a[field] ? -1 : 0);
      //    return (bRc);
      //});
    }

    getStateFromProps(props) {
      const { uiInfo, showDetails, filters, mobileTasksSort, mobileAlertsSort, mobileContext } = props;

      let field;
      let dir;

      switch (props.path) {
        case 'alerts':
          switch (props.alertsContextMenu) {
            case 'alerts':
              field = filters.sort.field;
              dir = filters.sort.dir;
              break;

            case 'noise':
              field = props.noiseAlertsSortParams.field;
              dir = props.noiseAlertsSortParams.dir;
              break;

            default:
              break;
          }
          break;
        case 'sensors':
          field = filters.sensorsSort.field;
          dir = filters.sensorsSort.dir;
          break;
        case 'couples':
          field = filters.couples.sort.field;
          dir = filters.couples.sort.dir;
          break;
        case 'manage':
          switch (this.props.managementContext) {
            case 'Customers':
              field = filters.customers.sort.field;
              dir = filters.customers.sort.dir;
              break;
            case 'Projects':
              field = filters.projects.sort.field;
              dir = filters.projects.sort.dir;
              break;
            case 'Users':
              field = filters.users.sort.field;
              dir = filters.users.sort.dir;
              break;
          }
          break;
        case 'mobile': {
          switch (mobileContext) {
            case 'Tasks':
              field = _.upperFirst(_.camelCase(mobileTasksSort.field));
              dir = mobileTasksSort.dir;
              break;
            case 'Alerts':
              field = _.upperFirst(_.camelCase(mobileAlertsSort.field));
              dir = mobileAlertsSort.dir;
              break;
            // case 'Devices':
            //   field = _.upperFirst(_.camelCase(mobileAlertsSort.field));
            //   dir = mobileAlertsSort.dir;
            //   break;
          }
        }
        case 'install': {
          switch (this.props.installContext) {
            case 'SOPs': {
              const sopsSort = filters.sops.sort;
              if (sopsSort) {
                field = sopsSort.field;
                dir = sopsSort.dir;
              }
              break;
            }
            case 'Interferences': {
              const interferencesSort = filters.interferencesSort;
              if (interferencesSort) {
                field = interferencesSort.field;
                dir = interferencesSort.dir;
              }
              break;
            }
            default:
              break;
          }

          break;
        }
        default:
          break;
      }

      const localSortProp = {
        sort: this.sortTableLocaly,
        sortBy: field,
        sortDir: dir,
      };
      const sortProp = {
        sort: this.sortTable,
        sortBy: field,
        sortDir: dir,
      };

      let pWidth = 0.395 * (uiInfo.pageWidth - 10);
      let pHeight = uiInfo.pageHeight;
      if (showDetails) {
        pHeight = 0.494 * uiInfo.pageHeight;
      }

      const panelStyle = { minHeight: 0.507 * uiInfo.pageHeight };

      const renderedFilters = this.renderFilters(props);
      return {
        renderedFilters,
        panelStyle,
        pHeight,
        pWidth,
        sortProp,
        localSortProp,
      };
    }

    filterItems = () => {
      const { items, path } = this.props;
      if (!(path === 'manage')) {
        return items;
      }
      if (!items || items.length === 0) {
        return [];
      }
      const keys = Object.keys(items[0]);

      return items.filter((item) => {
        return keys.some((key) => {
          const val = item[key];
          return (
            val &&
            val
              .toString()
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
          );
        });
      });
    };

    render() {
      const { renderedFilters, panelStyle, searchText } = this.state;

      const {
        uiInfo,
        title,
        items,
        displayAssessments,
        refreshData,
        onSearchItem,
        t,
      } = this.props;

      const filteredItems = searchText ? this.filterItems() : items;

      return (
        <div className='alerts' style={panelStyle}>
          <div className='alerts-wrp'>
            <div className='alerts-header'>
              {((this.props.path !== 'manage' &&
                this.props.path !== 'alerts') ||
                (this.props.path === 'alerts' &&
                  this.props.alertsContextMenu !== 'prsAlerts')) && (
                  <div className='actions'>
                    <i
                      className='icon-settings'
                      title={t('filters')}
                      // onClick={this.props.path !== 'mobile' ? this.toggleSettingsDisplay : null}
                      onClick={this.toggleSettingsDisplay}
                    />
                  </div>
                )}
              <div className='actions'>
                <i
                  className='icon-refresh'
                  title={t('reload_data')}
                  onClick={refreshData}
                />
              </div>
              <div className='actions'>
                <SearchItem
                  onChange={(v) => {
                    this.setState({
                      searchText: v,
                    });
                  }}
                  onSearchItem={onSearchItem}
                  path={this.props.path}
                  user={this.props.user}
                  managementContext={this.props.managementContext}
                  installContext={this.props.installContext}
                />
              </div>
              <h3 style={{ margin: '10px' }}>{title}</h3>
            </div>
            <div>
              <div className='alerts-filters'>
                {displayAssessments === false &&
                  (this.props.path === 'alerts' &&
                    this.props.alertsContextMenu === 'prsAlerts') === false && (
                    <label>{t('filters')}:</label>
                  )}
                {displayAssessments === false &&
                  (this.props.path === 'alerts' &&
                    this.props.alertsContextMenu === 'prsAlerts') === false &&
                  renderedFilters}
                {this.props.path === 'alerts' &&
                  this.props.alertsContextMenu === 'prsAlerts' && (
                    <ReportForDates done={this.props.loadPressureAlerts} />
                  )}
              </div>
              <InnerComponent
                {...this.state}
                {...this.props}
                items={filteredItems}
                rowClassNameGetter={this.rowClassNameGetter}
                rowClickEvent={this.onRowClick}
              />
              {this.props.path === 'alerts' && (
                <AlertsMenu
                  onChange={this.props.onChangeAlertsContext}
                  user={this.props.user}
                />
              )}
              {this.props.path === 'manage' && (
                <ManageMenu
                  addNew={this.props.addNewManageItem}
                  onChange={this.props.onChangeManageContext}
                />
              )}
              {
                // the path is like a "mode" that part of the URI like "install"
                this.props.path === 'install' && (
                  <InstallMenu
                    addNewInstallItem={this.props.addNewInstallItem}
                    installContext={this.props.installContext}
                    onChange={this.props.onChangeInstallContext}
                  />
                )
              }
              {
                // the path is like a "mode" that part of the URI like "mobile"
                this.props.path === 'mobile' && (
                  <MobileMenu
                    mobileContext={this.props.mobileContext}
                    onChange={this.props.onChangeMobileDisplayMode}
                  />
                )
              }
              {SettingsComponent != null && (
                <SettingsComponent
                  cleanEmptyFilters={this.cleanEmptyFilters}
                  processFilters={this.processFilters}
                  showSettings={this.state.showSettings}
                  toggleSettingsDisplay={this.toggleSettingsDisplay}
                  uiInfo={uiInfo}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  };

TableMixin.contextTypes = {
  t: PropTypes.func.isRequired,
};

TableMixin.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  uiInfo: PropTypes.object.isRequired,
};

export default TableMixin;
