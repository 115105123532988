import PropTypes from 'prop-types';
import React from 'react';

require('./SearchItem.scss');

function generateselect(value, label) {
  return { value, label };
}
const SearchItem = (props, context) => {
  const {
    path,
    managementContext,
    installContext,
    user,
    onSearchItem,
    options,
  } = props;
  const [input, setInput] = React.useState('');
  const [type, setType] = React.useState(undefined);
  const [placeholder, setPlaceholder] = React.useState(null);
  const [searchOptions, setSearchOptions] = React.useState([]);

  React.useEffect(() => {
    let type = 'ID';
    let placeholder;
    let searchOptions = [];

    switch (path) {
      case 'iq100b-mobile-modal':
        searchOptions = options;
        placeholder = context.t('search_users');
        break;
      case 'alerts':
        searchOptions = [generateselect('ID', context.t('alert_id'))];
        placeholder = context.t('search_alert');
        break;
      case 'sensors':
        placeholder = context.t('search_sensor');
        searchOptions = [
          generateselect('ID', context.t('sensor_id')),
          generateselect('DeviceID', context.t('device_id')),
          generateselect('AssetID', context.t('asset_id')),
          generateselect('Model', context.t('model')),
        ];
        if (user.technicianAdvanced) {
          searchOptions.push(
            generateselect('IEMI', context.t('iemi')),
            generateselect('SIMnumber', context.t('iccid'))
          );
        }
        break;
      case 'g5Devices':
        type = 'DeviceID';
        placeholder = context.t('search_sensor');
        searchOptions = [
          generateselect('DeviceID', context.t('device_id')),
          generateselect('AssetID', context.t('asset_id')),
          generateselect('Model', context.t('model')),
        ];
        if (user.technicianAdvanced) {
          searchOptions.push(
            generateselect('IEMI', context.t('iemi')),
            generateselect('SIMnumber', context.t('iccid'))
          );
        }
        break;
      case 'couples':
        placeholder = context.t('search_couple');
        searchOptions = [
          generateselect('ID', context.t('couple_id')),
          generateselect('SensorsID', context.t('sensor_id')),
        ];
        break;
      case 'mobile':
        type = 'TaskName';
        searchOptions = [generateselect('TaskName', context.t('task_name'))];
        placeholder = context.t('search_task');
        break;
      case 'manage':
        switch (managementContext) {
          case 'Users':
            placeholder = context.t('search_user');
            type = 'UserName';

            break;
          case 'Projects':
            placeholder = context.t('search_project');
            break;
          case 'Customers':
            placeholder = context.t('search_customer');
            break;
        }
        break;
      case 'install':
        switch (installContext) {
          case 'SOPs':
            placeholder = context.t('search_installation_point');
            searchOptions = [
              generateselect('ID', context.t('installation_point')),
              generateselect('AssetID', context.t('asset_id')),
            ];
            break;
          case 'Sensors':
            placeholder = context.t('search_sensor');
            searchOptions = [
              generateselect('ID', context.t('sensor_id')),
              generateselect('DeviceID', context.t('device_id')),
              generateselect('AssetID', context.t('asset_id')),
            ];
            if (user.technicianAdvanced) {
              searchOptions.push(generateselect('IEMI', context.t('iemi')));
            }
            break;
          case 'Interferences':
            placeholder = context.t('search_interference');
            searchOptions = [
              generateselect('ID', context.t('interference_id')),
            ];
            break;
        }
        break;
    }
    setInput('');
    setType(type);
    setSearchOptions(searchOptions);
    setPlaceholder(placeholder);
  }, [path, managementContext]);

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (input) {
      onSearchItem(input.trim(), type);
    }
  };

  const onChange = (e) => {
    props.onChange?.(e.target.value);
    setInput(e.target.value);
  };

  const onTypeChange = (option) => {
    setType(option.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <div className='search-item'>
          {path !== 'manage' && (
            <select onChange={onTypeChange} value={type}>
              {searchOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          )}

          <span className={path === 'manage' ? 'search-icon' : ''}>
            <input
              className={'input-field'}
              type='text'
              placeholder={placeholder}
              onChange={onChange}
              value={input}
            />
          </span>

          {props.path !== 'manage' && (
            <button
              className='search'
              disabled={input.length === 0}
              type='submit'
            />
          )}
        </div>
      </fieldset>
    </form>
  );
};

SearchItem.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SearchItem;
