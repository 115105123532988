import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware, getIconFeatures, setIndexes } from './MiddlewareActions';
import { setAddingNewManualAlertMode } from './AlertsActions';
import { setMapDrawMode } from "./setters";
import { getSamplesAction } from "./actions";
import { decToHex, formatMobileDeviceID } from "../containers/UIhelper/UIhelper";
import moment from 'moment';


const ACTIONS = {
  REQUEST_MOBILE_ALERTS: 'REQUEST_MOBILE_ALERTS',
  RECEIVE_MOBILE_ALERTS: 'RECEIVE_MOBILE_ALERTS',
  SELECT_MOBILE_ALERT: 'SELECT_MOBILE_ALERT',
  RECEIVE_MOBILE_ALERTS_IDS: 'RECEIVE_MOBILE_ALERTS_IDS',
  SORT_MOBILE_ALERTS_LOCALLY: 'SORT_MOBILE_ALERTS_LOCALLY',
  REQUEST_MOBILE_ALERT_DETAILS: 'REQUEST_MOBILE_ALERT_DETAILS ',
  RECEIVE_MOBILE_ALERT_DETAILS: 'RECEIVE_MOBILE_ALERT_DETAILS ',
  REQUEST_MOBILE_LEAK_SAMPLES: 'REQUEST_MOBILE_LEAK_SAMPLES ',
  RECEIVE_MOBILE_LEAK_SAMPLES: 'RECEIVE_MOBILE_LEAK_SAMPLES ',
  FILTER_MOBILE_ALERTS_LOCALLY: 'FILTER_MOBILE_ALERTS_LOCALLY',
  CLEAR_MOBILE_FILTERS: 'CLEAR_MOBILE_FILTERS',
  UPDATE_MOBILE_ALERT_REQ: 'UPDATE_MOBILE_ALERT_REQ',
  UPDATE_MOBILE_ALERT_RES: 'UPDATE_MOBILE_ALERT_RES',
  UPDATE_MOBILE_ALERT_TYPE: 'UPDATE_MOBILE_ALERT_TYPE',
  CHANGE_MOBILE_CONTEXT: 'CHANGE_MOBILE_CONTEXT',
  UPDATE_FILTERED_ITEMS: 'UPDATE_FILTERED_ITEMS',
};

// get mobile leak alerts endpoint
export const getMobileAlerts = (project, filters, sort) => {
  const path = endPoints.END_POINT + "/" + endPoints.MOBILE_LEAKS_ENDPOINT + "/projects/" + project + "/mobileAlerts" + "?filters=" + JSON.stringify(filters);

  return (dispatch, getState) => {
    const state = getState();
    dispatch(requestLeaksAction(project, filters, sort));
    return fetchMiddleware(path, {}, getState).then((json) => {
      if (json.status) {
        // const selectedFeatureType = state.leaksList.leaksByProject.selectedFeatureType;
        // console.log('selectedFeatureType in getMobileAlerts', selectedFeatureType);

        const field = state.mobile.alerts.mobileAlertsFilters.defFilters.sortBy;
        const dir = state.mobile.alerts.mobileAlertsFilters.defFilters.sortByDir;

        let alertsData = json.data;

        if (field !== undefined && field !== '' && dir !== undefined && dir !== '') {

          const leakData = json.data;
          alertsData = leakData.sort((a, b) => {
            const firstItem = (dir == 'asc') ? a : b;
            const secondItem = (dir == 'asc') ? b : a;

            if (firstItem[field] > secondItem[field]) {
              return 1;
            } else if (secondItem[field] > firstItem[field]) {
              return -1;
            } else {
              return 0;
            }

          });
        }
        const iconsFeatures = getIconFeatures('mobileAlert', alertsData);

        const indexMap = setIndexes(alertsData, 'alert_id');
        return [alertsData, iconsFeatures, indexMap];

      } else {
        return [[], {}, {}];
      }
    }).then((args) => {
      const state = getState();
      const workingPath = state.routing.locationBeforeTransitions.pathname.replace("/", "") || 'mobile';
      const selectedAlert = state.mobile.alerts.selectedAlert;

      if (workingPath == 'mobile') {
        if (!checkForSelectedFeature(...args, selectedAlert?.alert_id)) {
          dispatch(setSelectedMobileAlert(null));
        } else {
          dispatch(setSelectedMobileAlert(selectedAlert));

        }
      }
      // console.log('args', args);

      return dispatch(receiveLeaksAction(project, ...args));
    })
    // .then((args) => {
    //   dispatch(receiveLeaksAction(project, ...args));
    //   return (args);
    // });
  };
}

export const getAlertsDataAction = (
  isReceiveData = false,
  alerts = [],
  indexMap = {}
) => {
  return {
    type: isReceiveData
      ? ACTIONS.RECEIVE_MOBILE_ALERTS
      : ACTIONS.REQUEST_MOBILE_ALERTS,
    payload: { alerts, indexMap },
  };
};

const checkForSelectedFeature = (leaks, iconFeatures, indexMap, selectedFeature) => {
  return selectedFeature in indexMap;
}

export const selectMobileAlert = (alert) => {
  // console.log('alert in select mobile alert', alert);
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setSelectedMobileAlert(alert));

    const selectedProject = state.leaksList.selectedProject;
    let selectedAlertId;

    if (alert) {
      selectedAlertId = alert?.alert_id;
      // console.log('selectedAlertId', selectedAlertId);
      dispatch(getSamplesAction(selectedAlertId));
      dispatch(fetchMobileAlertSamples(selectedAlertId, selectedProject));
      // dispatch(setSelectedMobileAlert(alert));
    }
  };
};

export const setSelectedMobileAlert = (selectedFeature) => ({
  type: ACTIONS.SELECT_MOBILE_ALERT,
  selectedFeature: selectedFeature,
  payload: selectedFeature?.alert_id
});

export const fetchMobileAlertSamples = (alert, projectId) => {

  const path = `${endPoints.END_POINT}/mobilesamples/alerts/samples/${projectId}/${alert}`;

  return (dispatch, getState) => {

    dispatch(getSamplesAction());
    return fetchMiddleware(path, {}, getState).then((json) => {

      if (json.status) {
        const samples = json.data.map((sample) => {
          const hex = isNaN(sample.device_id) ? '--' : decToHex(Number(sample.device_id));
          const hexFormated = formatMobileDeviceID(hex);
          return { ...sample, device_id_hex: hexFormated };
        });
        const indexMap = setIndexes(samples, 'sample_uid');
        dispatch(getSamplesAction(true, samples, indexMap));
      }
    })
  }
}


// DETAILS
const requestMobileAlertDetails = (alert) => {
  return {
    type: ACTIONS.REQUEST_MOBILE_ALERT_DETAILS,
    payload: alert
  };
}

const receiveMobileAlertDetails = (alert, details) => {
  return {
    type: ACTIONS.RECEIVE_MOBILE_ALERT_DETAILS,
    payload: { alert, details },
  }
}

// const fetchMobileAlertDetails = (projectId, alertId) => {
//   console.log('fetchMobileAlertDetails runs');

//   const path = `${endPoints.END_POINT}/mobileAlerts/details/${projectId}/${alertId}`;

//   return (dispatch, getState) => {
//     dispatch(requestMobileAlertDetails(alertId))
//     return fetchMiddleware(path, {}, getState).then((json) => {
//       //Set status.
//       // if (json.data && json.data[0].LeakStatus != 1) {
//       //   json.data[0].X = json.data[0].FixX;
//       //   json.data[0].Y = json.data[0].FixY;
//       // }

//       dispatch(receiveMobileAlertDetails(alertId, json))
//     })
//   }
// }


// Update Alert Type
export const setMobileAlertType = (value) => {

  const action = {
    type: ACTIONS.UPDATE_MOBILE_ALERT_TYPE,
    value
  };
  return (dispatch, getState) => {
    dispatch(updateMobileAlert([{ field: 'AlertType', value: action.value }]));
  };
};



export const fetchLeaksIfNeeded = (project, force = false) => {

  return (dispatch, getState) => {
    const state = getState();
    const mobileAlertsFilters = state.mobile.alerts.mobileAlertsFilters.filters;

    return dispatch(getMobileAlerts(project, mobileAlertsFilters, {}))
  };
}

export const fetchLeaksIdsIfNeeded = (project) => {
  return (dispatch, getState) => {
    const state = getState();
    if (shouldFetchLeaksIds(state, project)) {
      return dispatch(fetchLeaksIds(project));
    }
  }
}
const shouldFetchLeaksIds = (state, project) => {
  return true;
}

const fetchLeaksIds = (project) => {
  var path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/leaksID";

  return (dispatch, getState) => {
    return fetchMiddleware(path, {}, getState)
      .then((json) => {
        dispatch(receiveLeaksIds(project, json.data));
      });
  }
}


const receiveLeaksIds = (project, leaksIds) => {
  return {
    type: ACTIONS.RECEIVE_MOBILE_ALERTS_IDS,
    project,
    leaksIds,
    receivedAt: Date.now()
  }
}


export const createNewMobileAlert = (taskId, data) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const project = state.leaksList.selectedProject;
      const mobileAlertsFilters = state.mobile.alerts.mobileAlertsFilters.filters;
      const insertedCoordinate = state.mapState.drawMode.data;
      if (insertedCoordinate != null) {
        data.Coordinate = insertedCoordinate[0];
      }
      const url = endPoints.END_POINT + `/mobilealerts/${taskId}`;
      const json = await fetchMiddleware(
        url,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data }),
        },
        getState
      )
      if (json.status) {
        dispatch(setAddingNewManualAlertMode(false));
        // exit map from draw mode:
        dispatch(setMapDrawMode(false))
        return dispatch(getMobileAlerts(project, mobileAlertsFilters, {}))
      }
    } catch (error) {
      console.log('error', error);
    }

  };
};

export const updateMobileAlert = (data) => {

  return (dispatch, getState) => {
    let addressData = null;
    const state = getState();
    const project = state.leaksList.selectedProject;
    const alertId = state.mobile.alerts.selectedAlert.alert_id;
    const alertsItems = state.mobile.alerts.items;
    const mobileAlertsFilters = state.mobile.alerts.mobileAlertsFilters.filters;

    const path = endPoints.END_POINT + "/" + endPoints.MOBILE_LEAKS_ENDPOINT + "/projects/" + project + "/mobileAlerts" + "/" + alertId;

    const tempData = state.temp;
    if (tempData.featureId == alertId) {
      addressData = state.temp.address;
    }

    dispatch(updateMobileAlertReq(project, alertId, data));
    return fetchMiddleware(path, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data, addressData })
    }, getState).then(json => {
      if (json.status == true) {
        dispatch(selectMobileAlert(null));

        dispatch(updateMobileAlertRes(project, alertId));

        dispatch(getMobileAlerts(project, mobileAlertsFilters, {})).then(() => {
          const newState = getState();
          const newIndexMap = newState.mobile.alerts.indexMap;
          const newItems = newState.mobile.alerts.items;
          const selectedAlert = newItems[newIndexMap[alertId]];
          dispatch(selectMobileAlert(selectedAlert));
        });

        dispatch(requestMobileAlertDetails(alertId))
        dispatch(receiveMobileAlertDetails(alertId, alertsItems))

      }
    });
  };
}

export const filterAlerts = (filters) => {
  return (dispatch, getState) => {
    // Filter out empty filters
    const nonEmptyFilters = filters.filters.filter((filter) => {
      if (filter.field === 'CreationDate') {
        return filter.from || filter.to;
      } else if (Array.isArray(filter.value)) {
        return filter.value.length > 0 && filter.value.some((v) => v && v.trim().length > 0);
      } else {
        return false;
      }
    });

    if (nonEmptyFilters.length) {
      const filteredItems = getState().mobile.alerts.items.filter((item) => {
        let found = true;
        for (const filter of nonEmptyFilters) {
          if (filter.field === 'TaskName') {
            const filterValues = filter.value.map((str) => str.toLowerCase().trim());
            if (!filterValues.includes(item.task_name.toLowerCase().trim())) {
              found = false;
              break;
            }
          } else if (filter.field === 'CreationDate') {
            let fromTs = null;
            let toTs = null;

            if (filter.from) {
              fromTs = moment(filter.from, 'YYYY-MM-DD').valueOf();
            }
            if (filter.to) {
              toTs = moment(filter.to, 'YYYY-MM-DD').valueOf();
            }

            if (!fromTs && !toTs) {
              // No date filters applied
              found = found && true;
            } else {
              const itemDateTs = moment(item.create_date).valueOf();

              if (fromTs && toTs) {
                if (itemDateTs >= fromTs && itemDateTs <= toTs) {
                  found = found && true;
                } else {
                  found = false;
                  break;
                }
              } else if (fromTs) {
                if (itemDateTs >= fromTs) {
                  found = found && true;
                } else {
                  found = false;
                  break;
                }
              } else if (toTs) {
                if (itemDateTs <= toTs) {
                  found = found && true;
                } else {
                  found = false;
                  break;
                }
              }
            }
          }
          // Add more filters if necessary
        }
        return found; // Include item only if it passes all filters
      });

      const newIndexMap = setIndexes(filteredItems, 'alert_id');
      dispatch({
        type: ACTIONS.FILTER_MOBILE_ALERTS_LOCALLY,
        payload: {
          items: filteredItems,
          indexMap: newIndexMap,
          filters: {
            defFilters: filters.defFilters,
            sort: filters.sort,
            filters: nonEmptyFilters,
          },
        },
      });
    } else {
      dispatch({
        type: ACTIONS.CLEAR_MOBILE_FILTERS,
        payload: {},
      });
    }
  };
};


export const sortAlertsLocally = (field, dir) => {
  return (dispatch, getState) => {
    const {
      mobile: {
        alerts: { items, filteredItems, activeFilter },
      },
    } = getState();

    const dataToSort = activeFilter ? filteredItems : items;

    const fieldMap = {
      'AlertId': 'alert_id',
      'CreationDate': 'create_date',
      'Intensity': 'intensity',
      'AlertType': 'alert_type',
      'AlertState': 'alert_state',
      'TaskName': 'task_name',
      'Address': 'address',
      'Comment': 'comment',
      // Add other mappings if necessary
    };

    const dataField = fieldMap[field] || field;

    const sortedItems = [...dataToSort].sort((a, b) => {
      let firstItem = dir === 'asc' ? a : b;
      let secondItem = dir === 'asc' ? b : a;

      let firstValue = firstItem[dataField];
      let secondValue = secondItem[dataField];

      // Handle date comparison for create_date
      if (dataField === 'create_date' && firstValue && secondValue) {
        firstValue = moment(firstValue).valueOf();
        secondValue = moment(secondValue).valueOf();
      }

      // Ensure values are strings for localeCompare if necessary
      if (typeof firstValue === 'string' && typeof secondValue === 'string') {
        return firstValue.localeCompare(secondValue);
      }

      // General comparison logic
      if (firstValue > secondValue) {
        return 1;
      } else if (firstValue < secondValue) {
        return -1;
      } else {
        return 0;
      }
    });

    const newIndexMap = setIndexes(sortedItems, 'alert_id');

    if (activeFilter) {
      dispatch({
        type: ACTIONS.UPDATE_FILTERED_ITEMS,
        payload: {
          items: sortedItems,
          indexMap: newIndexMap,
        },
      });
    } else {
      dispatch(getAlertsDataAction(true, sortedItems, newIndexMap));
    }
    dispatch({ type: ACTIONS.SORT_MOBILE_ALERTS_LOCALLY, field, dir });
  };
};



export const receiveLeaksAction = (project, alerts, iconFeatures, indexMap) => {
  return {
    type: ACTIONS.RECEIVE_MOBILE_ALERTS,
    payload: {
      project,
      alerts: alerts,
      iconFeatures,
      receivedAt: Date.now(),
      indexMap
    }
  }
}

export const requestLeaksAction = (project, filters, sort) => {
  return {
    type: ACTIONS.REQUEST_MOBILE_ALERTS,
    project,
    filters,
    sort
  };
}

export const updateMobileAlertReq = (project, alert, data) => {
  return {
    type: ACTIONS.UPDATE_MOBILE_ALERT_REQ,
    payload: {
      project,
      alert,
      data
    }
  };

}
// export const changeMobileContext = (modeState) => {
//   return {
//     type: ACTIONS.CHANGE_MOBILE_CONTEXT,
//     modeState: modeState
//   };
// }

export const updateMobileAlertRes = (project, alertId) => {
  // console.log('alertId in updateMobileAlertRes', alertId);
  return {
    type: ACTIONS.UPDATE_MOBILE_ALERT_RES,
    payload: {
      project,
      alertId
    }
  }
}

/* REDUCER */

const initialState = {
  items: [],
  indexMap: {},
  selectedAlert: null,
  filteredItems: [],
  filteredIndexMap: {},
  mobileAlertsSort: {
    field: 'CreationDate',
    dir: 'desc',
  },
  mobileAlertsFilters: { defFilters: [], filters: [] },
  activeFilter: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.REQUEST_MOBILE_ALERTS:
      return {
        ...state,
        items: [],
        indexMap: {},
      };

    case ACTIONS.RECEIVE_MOBILE_ALERTS:
      return {
        ...state,
        items: action.payload.alerts,
        indexMap: action.payload.indexMap,
      };
    // case ACTIONS.CHANGE_MOBILE_CONTEXT:
    //   return { ...state, contextType: action.context };

    case ACTIONS.SELECT_MOBILE_ALERT:
      return {
        ...state,
        selectedAlert: action.selectedFeature,
        // selectedFeatureType: action.FeatureType,
      };

    case ACTIONS.REQUEST_MOBILE_ALERT_DETAILS:
      return {
        ...state,
        alert: action.payload.alert
      };
    case ACTIONS.RECEIVE_MOBILE_ALERT_DETAILS:
      return {
        ...state,
        alert: action.payload.alert,
        details: action.payload.details
      };
    case ACTIONS.UPDATE_MOBILE_ALERT_REQ:
      return {
        ...state,
        project: action.payload.project,
        alert: action.payload.alert,
        data: action.payload.data
      };
    case ACTIONS.UPDATE_MOBILE_ALERT_RES:
      return {
        ...state,
        project: action.payload.project,
        alert: action.payload.alert,
      };
    case ACTIONS.FILTER_MOBILE_ALERTS_LOCALLY:
      return {
        ...state,
        mobileAlertsFilters: { ...action.payload.filters },
        activeFilter: action.payload.filters.filters.length > 0,
        filteredItems: action.payload.items,
        filteredIndexMap: action.payload.indexMap,
      };
    case ACTIONS.UPDATE_FILTERED_ITEMS:
      return {
        ...state,
        filteredItems: action.payload.items,
        filteredIndexMap: action.payload.indexMap,
      };
    case ACTIONS.CLEAR_MOBILE_FILTERS:
      return {
        ...state,
        mobileAlertsFilters: { defFilters: [], filters: [] },
        activeFilter: false,
        filteredItems: [],
        filteredIndexMap: {},
      };
    case ACTIONS.SORT_MOBILE_ALERTS_LOCALLY:
      return {
        ...state,
        mobileAlertsSort: {
          field: action.field,
          dir: action.dir,
        },
      };
    case ACTIONS.UPDATE_MOBILE_ALERT_TYPE:
      return {
        ...state,
        alertType: {
          field: action.value,
        },
      };


    default:
      return state;
  }
};

export default reducer;
