import PropTypes from 'prop-types';
import React from 'react';
import CorrelationGraph from './CorrelationGraph';
import DebugWaveData from './DebugWaveData';
import SpectrogramGraph from './SpectrogramGraph';
// import DistanceIntensityScatterTrend from './DistanceIntensityScatterTrend';
import DateDistanceScatterTrend from './DateDistanceScatterTrend';
import SensorsNoiseGraph from './SensorsNoiseGraph';

import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';

// import Fab from '@mui/material/Fab';
import HearingIcon from '@mui/icons-material/Hearing';
import IconButton from '@mui/material/IconButton';

import { Button } from '@mui/material';
import AvGraphModal from './AvGraphModal';
import SampleAdvancedInfoTabs from './SampleAdvancedInfoTabs';
const classNames = require('classnames');

require('./SampleInfo.scss');

export default class SampleInfo extends React.Component {

  constructor(props) {

    super(props);
    this.closePanel = this.closePanel.bind(this);
    this.toggleAdvancedGraphs = this.toggleAdvancedGraphs.bind(this);

    this.state = {
      showAdvancedGraphs: false,
      audioOpen: false,
      avGraphOpen: false,
    };
  }

  closePanel() {
    const { selectSample } = this.props;
    selectSample();
  }

  toggleAdvancedGraphs() {
    const nextAdvancedGraphsState = !this.state.showAdvancedGraphs;
    this.setState({
      showAdvancedGraphs: nextAdvancedGraphsState
    });
  }

  toggleAudioOpen = () => {
    this.setState({
      audioOpen: !this.state.audioOpen
    });
  }

  toggleAvGraph = () => {
    this.setState({
      avGraphOpen: !this.state.avGraphOpen
    });
  }

  render() {
    const {
      selectedSample,
      downloadFile,
    } = this.props;
    const panelClasses = classNames(
      'sample-info'
    );

    const moreInfoButtonClass = this.state.showAdvancedGraphs ? 'icon-show-more-info open' : 'icon-show-more-info';

    return (
      <div className={panelClasses}>
        <i className="icon-close" onClick={this.closePanel} />
        {this.props.user.analysesExpert &&
          <i className={moreInfoButtonClass} onClick={this.toggleAdvancedGraphs} />
        }

        <SampleAdvancedInfoTabs
          show={this.state.showAdvancedGraphs}
          displayType={this.props.type}
          audioData={this.props.audioData}
          analysisData={this.props.analysisData}
          historyData={this.props.history}
          historyNoiseData={this.props.historyNoiseData}
          correlationAve={this.props.correlationAve}
          histogramAve={this.props.histogramAve}
          graphParams={this.props.graphParams}
          graphsDistancesForAxis={this.props.distancesForAxis}
          bias={this.props.bias}
          timeZone={this.props.timeZone}
          selectedSample={this.props.selectedSample}
          rawData={this.props.rawData}
          tensor={this.props.tensor}
          probabilityData={this.props.probability}
          correlationInstance={this.props.correlationInstance}
          histogramInstance={this.props.histogramInstance}
          maxProbabilityPositions={this.props.maxProbabilityPositions}
          sensorsPositions={this.props.sensorsPositions}
          maxProbabilityGraphs={this.props.maxProbabilityGraphs}
          distanceFactor={this.props.distanceFactor}
          user={this.props.user}
        />

        <Tooltip
          placement='right'
          enterDelay={1000}
          title={this.context.t('op_audio_table', { op: this.state.audioOpen ? 'Hide' : 'Show' })}
        >
          <IconButton
            onClick={() => this.toggleAudioOpen()}
            style={{
              padding: '10px',
              borderRadius: '50%',
              position: 'absolute',
              bottom: '2px',
              left: '2px',
              zIndex: 1,
              cursor: 'pointer',
              color: 'black',
              backgroundColor: 'lightskyblue'
            }}>
            <HearingIcon />
          </IconButton>
        </Tooltip>

        <div>
          <div className="sample-graphs">
            {this.props.type === 'avg' ?
              <DateDistanceScatterTrend
                title={this.context.t('trend')}
                // titleX={this.context.t('Dates')}
                titleY={this.context.t('distances')}
                tensor={this.props.tensor}
                timeZone={this.props.timeZone}
                distanceFactor={this.props.distanceFactor}
                small
              />
              :
              <SpectrogramGraph
                graphTitle={this.context.t('avg_max_spectrogram')}
                data={this.props.maxProbabilityGraphs[0].histogram || []}
                xValues={{
                  start: this.props.graphParams.freq.start,
                  step: this.props.graphParams.freq.step
                }}
                xTitle={this.context.t('frequency')}
                yTitle={this.context.t('distance')}
                yAxisLabels={this.props.distancesForAxis}
                leaksMarkers={this.props.maxProbabilityPositions}
                sensorsPositions={this.props.sensorsPositions}
                small
              />
            }

            {this.props.type === 'avg' ?
              <SpectrogramGraph
                graphTitle={this.context.t('avg_max_spectrogram')}
                data={this.props.maxProbabilityGraphs[0].histogram || []}
                xValues={{
                  start: this.props.graphParams.freq.start,
                  step: this.props.graphParams.freq.step
                }}
                xTitle={this.context.t('frequency')}
                yTitle={this.context.t('distance')}
                yAxisLabels={this.props.distancesForAxis}
                leaksMarkers={this.props.maxProbabilityPositions}
                sensorsPositions={this.props.sensorsPositions}
                small
              />
              :
              <CorrelationGraph
                title={this.context.t('avg_max_correlation')}
                data={[this.props.maxProbabilityGraphs[0].correlation || []]}
                factor={this.props.graphParams.factor}
                xTitle={this.context.t('distance')}
                yTitle={this.context.t('contrast')}
                xAxisLabels={this.props.distancesForAxis}
                leaksMarkers={this.props.maxProbabilityPositions}
                sensorsOnAxis={this.props.sensorsPositions}
                small
              />

              // <SpectrogramGraph
              //   graphTitle={this.context.t('{name} Spectrogram', { name: this.props.type === 'avg' ? 'AVG' : 'Instance' })}
              //   data={this.props.type === 'avg' ? this.props.histogramAve : this.props.histogramInstance}
              //   xValues={{
              //     start: this.props.graphParams.freq.start,
              //     step: this.props.graphParams.freq.step
              //   }}
              //   xTitle={this.context.t('Frequency')}
              //   yAxisLabels={this.props.distancesForAxis}
              //   leaksMarkers={this.props.maxProbabilityPositions}
              //   sensorsPositions={this.props.sensorsPositions}
              //   small
              //   />

            }

            {
              this.props.type === 'avg' ?
                <CorrelationGraph
                  title={this.context.t('avg_max_correlation')}
                  data={[this.props.maxProbabilityGraphs[0].correlation || []]}
                  factor={this.props.graphParams.factor}
                  xTitle={this.context.t('distance')}
                  yTitle={this.context.t('contrast')}
                  xAxisLabels={this.props.distancesForAxis}
                  leaksMarkers={this.props.maxProbabilityPositions}
                  sensorsOnAxis={this.props.sensorsPositions}
                  small
                />
                :
                <CorrelationGraph
                  title={this.context.t('name_correlation', { name: this.props.type === 'avg' ? 'AVG' : 'Instance' })}
                  data={[this.props.type === 'avg' ? this.props.correlationAve : this.props.correlationInstance]}
                  factor={this.props.graphParams.factor}
                  xTitle={this.context.t('distance')}
                  yTitle={this.context.t('contrast')}
                  xAxisLabels={this.props.distancesForAxis}
                  leaksMarkers={this.props.maxProbabilityPositions}
                  sensorsOnAxis={this.props.sensorsPositions}
                  small
                />
            }

            {this.props.type === 'avg' ?
              <SensorsNoiseGraph
                title={this.context.t('noise_NC1_NC2')}
                titleY={this.context.t('intensity')}
                titleX={this.context.t('date')}
                historyNoiseData={this.props.historyNoiseData}
                timeZone={this.props.timeZone}
                small
              />
              :
              <DateDistanceScatterTrend
                title={this.context.t('trend')}
                // titleX={this.context.t('Dates')}
                titleY={this.context.t('distances')}
                tensor={this.props.tensor}
                timeZone={this.props.timeZone}
                distanceFactor={this.props.distanceFactor}
                small
              />
            }
          </div>

          {this.props.user.isAQS && this.props.rawData.av_graph_cooked &&
            <Button
              variant='contained'
              style={{
                display: 'block',
                margin: '0 auto'
              }}
              onClick={this.toggleAvGraph}
            >{this.context.t('show_av')}</Button>
          }

          {this.props.user.isAQS && this.props.rawData.av_graph_cooked &&
            <AvGraphModal
              open={this.state.avGraphOpen}
              closeAvGraph={this.toggleAvGraph}
              avData={this.props.rawData.av_graph_cooked}
              factor={this.props.graphParams.factor}
              xAxisLabels={this.props.distancesForAxis}
              sensorsOnAxis={this.props.sensorsPositions}
            />
          }

          <Collapse in={this.state.audioOpen} timeout={600} unmountOnExit>
            <div className='sample-graphs stripe'>
              <DebugWaveData
                context={this.context}
                downloadFile={downloadFile}
                audioFs={this.props.rawData.fs}
                user={this.props.user}
                audioData={this.props.audioData}
                classificationsOptions={this.props.audioClassificationsOptions}
                setClassificationForAudio={this.props.setClassificationForAudio}
              />
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}

SampleInfo.contextTypes = {
  t: PropTypes.func.isRequired
};
