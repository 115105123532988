export const en = {
  Sensor_id: 'Sensor ID',
  X: '',
  a: 'A',
  ownership: 'Ownership',
  account: 'Account',
  active: 'Active',
  add: 'Add',
  alert_classification: 'Alert Classification',
  add_new_noise_alert: 'Add New Noise Alert',
  add_new_task: 'Add New Task',
  add_sensor: 'Add Sensor',
  address: 'Address',
  adhoc_correlation: 'Adhoc Correlation',
  advance_alerts: 'Advance Alerts',
  advance_pipes: 'Advance Pipes',
  advanced: 'Advanced',
  agc_config: 'AGC Config',
  agreement: 'Agreement',
  alert: 'Alert',
  alert_alertId: 'Alert {alertId}',
  alert_comment: 'Alert Comment',
  alert_id: 'Alert ID',
  a_valid_segment: 'A Valid Segment',
  avg_last_correlation: 'AVG Last Correlation',
  avg_last_spectrogram: 'AVG Last spectrogram',
  above_under: 'Above / Under',
  above: 'Above',
  action: 'Action',
  address_: 'Address: ',
  addresses_address1_address2: 'Addresses: {address1}, {address2}',
  alert_date: 'Alert Date: ',
  alert_id_filter: 'Alert ID Filter',
  alert_id_id: 'Alert ID {id}',
  alert_id_: 'Alert ID: ',
  alert_id_id_: 'Alert ID: {id}',
  id: 'ID',
  alert_status: 'Alert Status',
  alert_type: 'Alert Type',
  alert_alertid: 'Alert {alertId}',
  alerts_options: 'Alerts Options',
  alerts: 'Alerts',
  allow_false_positive: 'Allow False Positive',
  allow_prod2_run: 'Allow Prod2 Run',
  allowprod2run: 'AllowProd2Run',
  analyzer: 'Analyzer',
  apply_changes: 'Apply changes',
  are_you_sure_you_want_to_remove_this_device:
    'Are you sure you want to remove this device?',
  are_you_sure_you_want_to_uninstall_the_device_and_remove_this_sensor:
    'Are you sure you want to Uninstall the Device And remove this Sensor?',
  are_you_sure_you_want_to_uninstall_the_device:
    'Are you sure you want to Uninstall the Device?',
  are_you_sure_you_want_to_remove_this_sensor:
    'Are you sure you want to remove this Sensor?',
  are_you_sure: 'Are you sure?',
  this_action_will_rerun_override:
    'This action will override the previous samples',
  confirmation_success: 'Here is a gentle confirmation that your action was successful',
  confirmation_error: 'Your action was not successful',
  assessment_id_id: 'Assessment ID: {id}',
  assetid_asset: 'AssetID: {asset}',
  attenuation: 'Attenuation',
  audio_recording_intensity_calculated_with_electrical_interference_filltering:
    'Audio recording intensity calculated with electrical interference filltering',
  recording_time_1: 'Time of First Recording',
  recording_time_2: 'Time of Second Recording',
  recording_time_3: 'Time of Third Recording',
  authorization: 'Authorization',
  auto_reset_at_lastautoresetdate: 'Auto Reset At {lastAutoResetDate}',
  auto_reset_count_autoresetcount: 'Auto Reset Count {autoResetCount}',
  auto: 'Auto',
  autoclosedate: 'AutoCloseDate',
  average_correlation_contrast: 'Average Correlation Contrast',
  average_correlation: 'Average Correlation',
  average_spectrogram: 'Average Spectrogram',
  azbest_cement: 'Asbestos cement',

  alert_id_param: 'Alert ID: {id}',
  alert_state: 'Alert State',
  alert_status2: 'Alert Status:',
  alert_statuses: 'Alert Statuses',
  all_boundaries_selected: 'All Boundaries selected',
  analyze: 'analyze',
  and_the: '” and the “',
  application_services: 'Application Services',
  applies_only_to_us_government_licensees:
    '[Applies only to U.S. Government Licensees]',
  aquarius: 'AQUARIUS',
  archived: 'Archived',
  are_you_sure_you_want_to_delete_this_type:
    'Are you sure you want to delete this {type}?',
  asc: 'ASC',
  assembly: 'Assembly',
  blank_space: ' ',
  asset_id: 'Asset ID',
  asset_id_param: 'Asset ID: {asset}',
  exported_to_google_sheet_successfully:
    'Exported to Google Sheet successfully',
  open_google_sheet: 'Open Google Sheet',
  export_to_excel: 'Export to Excel',
  export_alerts_history: 'Export Alerts History',
  export_noise_alerts_history: 'Export Noise Alerts History',
  sensors_maintenance: 'Sensors Maintenance',
  sensors_activity_report: 'Sensors Activity Report',
  sensors_technician_report: 'Sensors Technician Report',
  sensors_admin_report: 'Sensors Admin Report',
  there_is_no_data_available: 'There is no data available',
  no_data_available: 'No data available',
  based_on_last_weeks_data: 'Based on last weeks data',
  no_data_available: 'No data available',
  network_response_not_ok: 'Network response was not ok',
  technician_report: 'Technician Report',
  report_type: 'Report Type',
  select_customer: 'Select Customer',
  rerun_historical_data: 'Rerun Historical Data',
  select_time_frame: 'Select Time Frame',
  time_source: 'Time Source',
  valve_leak: 'Valve Leak',
  rerun: 'Rerun',
  please_select: 'Please Select',
  export_shp_file: 'Export SHP File',
  export_alerts_to_excel: 'Export Alerts to Excel',
  export_all_transients_to_excel: 'Export All Transients to Excel',
  hello_username: 'Hello {userName}',
  about: 'About',
  help: 'Help',
  logout: 'Logout',
  licneces: 'Licences',
  local: 'Local',
  gmt: 'GMT',
  last_correlation: 'Last Correlation',
  last_spectrogram: 'Last Spectrogram',
  radio_quality: 'Radio [dt: {dt}, quality: {quality}]',
  please_enter_your_new_password_below: 'Please enter your new password below',
  enter_new_password_again: 'Enter new password again',
  meter: 'm',
  fit: 'ft',
  not_sensitive: 'Not Sensitive',
  is_emi: 'Is EMI',
  is_saturation: 'Is Saturation',
  is_delayed_wakeup: 'Is Delayed Wakeup',
  iq_100b_reports: 'IQ100B Reports',
  is_white_noise: 'Is White Noise',
  is_overlapping: 'Is Overlapping',
  audio: 'Audio',
  device_installation_date:
    'Device Installation Date: {deviceInstallationDate}',
  device_generation: 'Device Generation',
  audio_clean: 'Audio Clean',
  authorized_distributor: 'Authorized Distributor',
  authorized_users: 'Authorized Users',
  authorized_users_and_use_limitations: 'Authorized Users and Use; Limitations',
  automatic_synchronization: 'Automatic Synchronization',
  sync_method: 'Sync Method',
  average: 'average',
  avg_max_correlation: 'AVG Max Correlation',
  avg_max_spectrogram: 'AVG Max spectrogram',

  bit_information: 'BIT Information',
  bit_response_value: 'BIT Response Value',
  bit_type: 'BIT Type',
  back: 'Back',
  bad_status: 'Bad status',
  battery_voltage_post: 'Battery Voltage Post',
  battery_voltage_pre: 'Battery Voltage Pre',
  battery: 'Battery',
  bounding: 'Bounding',
  csq: 'CSQ',
  cannot_find_your_search: 'Can not find your search',
  cast_iron: 'CastIron',
  change_statename_of_leak_id_from_prevstate_to_nextstate:
    'Change {stateName} of leak {id} from {prevState} to {nextState}',
  change_statename_of_alert_type_from_prevstate_to_nextstate:
    'Change {stateName} of {alertType} {id} from {prevState} to {nextState}',
  circle: 'Circle',
  city: 'City',
  click_to_set_current_map_position_as_project_center:
    'Click to set current map position as project center',
  close_aleart: 'Close Aleart',
  comm_ratio: 'Comm Ratio',
  comment: 'Comment',
  comment_date: 'Comment Date',
  communication_problem: 'Communication Problem',
  compression_enabled: 'Compression Enabled',
  compression: 'Compression',

  b: 'B',
  back_to_home_view: 'Back To Home View',
  back_to_qalertsq: 'Back to Alerts',
  backfill: 'Backfill',
  backfill_comment: 'Backfill Comment',
  bedding_comment: 'Bedding Comment',
  bedding_type: 'Bedding Type',
  brass: 'Brass',

  config_record_duration: 'Config Record Duration',
  config_status: 'Config Status',
  consumption_time_: 'Consumption Time: ',
  consumtion: 'Consumtion',
  consumtion_: 'Consumtion: ',
  contact_email: 'Contact Email',
  contact_person: 'Contact Person',
  contact_phone_1: 'Contact Phone 1',
  contact_phone_2: 'Contact Phone 2',
  correlation_audio: 'Correlation Audio',
  correlation_radio: 'Correlation Radio',
  couple_coverage: 'Couple Coverage',
  couple_deleted_successfuly: 'Couple Deleted Successfuly',
  couple_id_: 'Couple ID',
  couple_id_coupleid_material_count_alerts:
    'Couple ID: {CoupleID},   Material: {material},   {Count} Alerts',
  couple_id_id: 'Couple ID: {id}',
  couple_path: 'Couple Path:',
  couple_coupleid: 'Couple {coupleId}',
  couple_id_id_: 'Couple {id}',
  couples_days_back_for_only_updated: 'Couples Days Back For Only Updated',
  create_couple: 'Create Couple',
  create_task: 'Create Task',
  create_new_customer: 'Create New Customer',
  create_new_project: 'Create New Project',
  create_new_sop: 'Create New SOP',
  create_new_user: 'Create New User',
  create_new_area_of_interest: 'Create new Area Of Interest',
  creation_time: 'Creation Time',
  customer_id_: 'Customer ID: ',
  customer_name: 'Customer Name',
  customer_customer: 'Customer: {customer}',
  customer_name_: 'Customer: {name}',
  customers: 'Customers',
  c: 'C',
  cancel: 'Cancel',
  yes: 'Yes',
  changed: 'Changed',
  choice_of_law_and_forum: 'Choice of Law and Forum',
  choose_location_for_new_installation_point:
    'Choose location for new Installation Point',
  claims: 'Claims',
  clarity: 'Clarity:',
  english: 'English',
  hebrew: 'Hebrew',
  layer_manager: 'Layer Manager',
  satellite_view: 'Satellite View',
  show_alerts: 'Show Alerts',
  minimal_noise_intensity: 'Minimal Noise Intensity',
  filtered_noise_intensity: 'Filtered Noise Intensity',
  show_mobile_samples: 'Show Mobile Samples',
  show_mobile_alerts: 'Show Mobile Alerts',
  show_sensors: 'Show Sensors',
  show_installation_points: 'Show Installation Points',
  show_areas_of_interest: 'Show Areas of Interest',
  pipes_by_material: 'Pipes by Material',
  pipes_by_diameter: 'Pipes by Diameter',
  pipes_by_pressure: 'Pipes by Pressure',
  pipes_by_placement_year: 'Pipes by Placement Year',
  couples_coverage: 'Couples Coverage',
  show_couples_path: 'Show Couples Path',
  project_boundaries: 'Project Boundaries',
  show_water_meter_layer: 'Show Water Meter Layer',
  project_coverage: 'Project Coverage',
  email: 'Email',
  password: 'Password',
  forgot_password: 'Forgot Password',
  login: 'Login',
  manual_reset_at: 'Manual Reset At {lastManualResetDate}',
  manual_reset_count: 'Manual Reset Count {manualResetCount}',
  auto_reset_at: 'Auto Reset At {lastAutoResetDate}',
  auto_reset_count: 'Auto Reset Count {autoResetCount}',
  sensors_activity: 'Sensors Activity',
  select_month: 'Select Month',
  boundary: 'Boundary',
  include_archived_sensors: 'Include Archived Sensors',
  all: 'All',
  sensor_1: 'Sensor 1',
  sensor_2: 'Sensor 2',
  device: 'Device',
  pressure: 'Pressure',
  g5_status: 'G5 Status',
  classification: 'Classification',
  clear_selection: 'Clear Selection',
  click_on_map_to_set_coordinate: 'Click on Map to set coordinate',
  complete_alert_details_and_save: 'Please Complete Alert Details and Save',
  click_to_download: 'Click to download',
  close: 'Close',
  close_noise_alert: 'Close Noise Alert',
  close_valve_alert: 'Close Valve Alert',
  closure_date: 'Closure date:',
  code: 'Code',
  collectively_the: '”, collectively, the “',
  commands: 'Commands',
  commented: 'Commented: ',
  comments: 'Comments',
  communications_service: 'Communications Service”',
  compaction: 'Compaction',
  compliance_with_laws: 'Compliance with laws',
  concrete: 'Concrete',
  config: 'Config',
  config_duration: 'Config Duration',
  configuration: 'Configuration',
  configure: 'Configure',
  consent_to: 'Consent to',
  consent_to_use_of_data: 'Consent to Use of Data',
  consumption: 'Consumption:',
  consumption_time: 'Consumption Time:',
  contact_details: 'Contact Details',
  conten: 'Conten',
  content: 'Content',
  contrast: 'Contrast',
  control_buttons: 'Control Buttons',
  coordinate: 'Coordinate',
  copper: 'Copper',
  copyright_2021_aquarius_spectrumbrbr_all_rights_re:
    'Copyright © 2021 Aquarius Spectrum All rights reserved. Aquarius Spectrum1 HaMahshev, Netanya 4250711, Israel Phone: +972-74-7136644 Fax: +972-4-6709014',
  corr_interval: 'Corr Interval',
  corr_num_samples: 'Corr Num Samples',
  correlation: 'Correlation',
  corrosion_type: 'Corrosion Type',
  cost: 'Cost:',
  count: 'Count',
  couple_and_id: 'Couple {id}:',
  couple_assessment_report: 'Couple Assessment Report',
  couple_id: 'Couple ID',
  couple_id2: 'Couple {coupleId}:',
  couple_length: 'Couple Length',
  couple_comment: 'Couple Comment',
  couples: 'Couples',
  couples_options: 'Couples Options',
  crack_diameter_inches: 'Crack Diameter (inches)',
  create: 'Create',
  create_a_new_noise_alert: 'Create a new Noise Alert',
  create_a_new_valve_alert: 'Create a new Valve Alert',
  create_date: 'Create Date',
  create_new_installation_point: 'Create new Installation Point',
  created_new_alert: 'Created new alert',
  current_device_id: 'Current Device ID',
  current_status: 'Current Status',
  customer: 'Customer',
  customer_account: 'Customer Account',
  customer_customerParam: 'Customer: {customer}',
  flow_param: 'Flow: {flow}',
  from_date_date: 'From Date: {date}',
  amplitude: 'Amplitude',
  file: 'File',
  min_rms: 'Min RMS',
  catm_flags: 'catm_flags',
  hw_v: 'HW V',
  classify: 'classify',
  min: 'min',
  nc_3: 'nc_3',
  threshold: 'threshold',
  contact_name: 'Contact Name',
  hi: 'hi',
  assambly: 'assambly',
  g5_intensity: 'G5 Intensity',
  comm_messages: 'Comm Messages',
  installation_point: 'Installation Point',
  bit_samples: 'Bit Samples',
  bit_messages: 'Bit Messages',
  bit: 'Bit',
  gain: 'Gain',
  download: 'Download',
  seconds: 'Seconds',
  freq: 'Freq',
  noise: 'Noise',
  low: 'Low',
  radio_clean: 'Radio Clean',
  power: 'Power',
  radio_frequency_data: 'Radio Frequency Data',
  noise_intensity_min_band_power: 'Noise Intensity Min Band Power',
  noise_sampling_taken_during_audio: 'Noise sampling taken by the sensor during audio recording',
  audio_data_size: 'Audio Data Size',
  request_time: 'Request Time',
  response_time: 'Response Time',
  selected_fm_channel: 'Selected FM Channel',
  tp_avg: 'TP Avg',
  tp_rms: 'TP RMS',
  std_agc: 'STD AGC',
  std_agc_2: 'STD AGC 2',
  tp_rms_2: 'TP RMS 2',
  original: 'Original',
  audio_gain: 'Audio Gain',
  imsi: 'IMSI',
  batt: 'Batt',
  acc_tech: 'Acc Tech',
  tech: 'Tech',
  nbiot_flags: 'nbiot_flags',
  nbiot_tx_tout: 'nbiot_tx_tout',
  nbiot_rx_tout: 'nbiot_rx_tout',
  nbiot_tx_power: 'nbiot_tx_power',
  nbiot_tx_gain: 'nbiot_tx_gain',
  nbiot_packet: 'nbiot_packet',
  catm_packet: 'catm_packet',
  catm_rx_tout: 'catm_rx_tout',
  catm_tx_tout: 'catm_tx_tout',

  rsrq_db: 'RSRQ [db]',
  rsrp_db: 'RSRP [db]',
  hw_rev: 'HW Rev.',
  nb: 'NB',
  unknown: 'Unknown',
  cat_m: 'Cat-M',
  fw_rev: 'HF Rev.',
  fw_v: 'FW V.',
  rst: 'RST',
  orig_2: 'Orig 2',
  technology: 'Technology',
  cell_id: 'Cell id',
  med: 'Med',
  high: 'High',
  customer_id: 'Customer ID:',
  customer_indemnification: 'Customer Indemnification',
  dmas_layer: process.env.REACT_APP_DMA_TITLE,
  dates: 'Dates',
  days_correlation_incative: 'Days Correlation Inactive',
  days_inactive: 'Days Inactive',
  decive_type: 'Decive Type',
  delete_failed: 'Delete Failed',
  device_id_: 'Device ID: ',
  device_id_device: 'Device ID: {device}',
  device_installation: 'Device Installation',
  device_already_installed_in_project_project:
    'Device is already installed in Project {project}',
  diameter_placement_year: 'Diameter / Placement Year',
  dist_m: 'Dist[m]',
  do_not_take_0_samples_in_average: 'Do Not Take 0 samples In Average',
  done: 'Done',
  download_audio: 'Download audio',
  download_log_file: 'Download log file',
  draw_mode: 'Draw Mode',
  dual_signal: 'Dual Signal',
  d: 'D',
  dashboard: 'Dashboard',
  date: 'Date',
  day: 'Day',
  days_detected: 'Days detected:',
  delete: 'Delete',
  delete_type_id: 'Delete {type} {id}',
  deletion_of_customer_data: 'Deletion of Customer Data',
  deletion_of_data: 'Deletion of Data',
  desc: 'DESC',
  design_operating_press: 'Design Operating Press',
  details: 'Details',
  detected: 'Detected',
  device_configuration_messages_logs: 'Device Configuration Messages Logs',
  device_id: 'Device ID',
  device_id_val: 'Device {id}',
  mobile_alert_id_val: 'Mobile Alert {id}',
  frequency_hz: 'Frequency: {freq} [Hz]',
  duration_sec: 'Duration: [sec]',
  access_point: 'Access Point',
  fill_addresses: 'Fill Addresses',
  task_samples: 'Task Samples',
  samples_count: 'Samples Count',
  creation_date_default: 'Creation Date (default)',
  tasks: 'Tasks',
  tasks_options: 'Tasks Options',
  tasks_filters: 'Tasks Filters',
  alerts_filters: 'Alerts Filters',
  creation_date: 'Creation Date',
  survey: 'Survey',
  devices: 'Devices',
  clean: 'Clean',
  raw: 'Raw',
  low_intensity: 'Low Intensity',
  user_name: 'User Name',
  duration: 'Duration',
  intensity_raw: 'Intensity [raw]',
  intensity_db: 'Intensity:[dB]',
  intensity: 'Intensity',
  battery_level: 'Battery Level',
  terms_and_conditions: 'Terms and Conditions',
  battery_post: 'Battery Post',
  fw_version: 'FW Version',
  hw_version: 'HW Version',
  rsrq: 'RSRQ',
  rsrp: 'RSRP',
  rssi: 'RSSI',
  snr: 'SNR',
  noise_gain: 'Noise Gain',
  noise_min_rms: 'Noise Min RMS',
  normalize_noise: 'Normalize Noise',
  by_clicking_login_you_agree_to_our: 'By clicking "Login", you agree to our',
  fm_radio_channel: 'FM Radio Channel',
  and_our: 'and our',

  get_audio_config: 'Get Audio Config',
  get_time_zone: 'Get Time Zone',
  get_lte_mode: 'Get LTE Mode',
  get_server: 'Get Server',
  get_fm_channel: 'Get FM Channel',
  start_fm_scan: 'Start FM Scan',
  get_apn: 'Get APN',
  get_valve_leak: 'Get Valve Leak',
  get_agc_conf: 'Get AGC Conf',
  get_fallback_server: 'Get Fallback Server',
  get_capability: 'Get Capability',
  get_lte_configuration: 'Get LTE Configuration',
  bit_request: 'BIT Request',
  activate_toolkit: 'Activate Toolkit',
  you_are_about_to_take_a_significant_action:
    'You are about to take a significant action',
  sure_you_want_to_deactivate_sensors: 'Sure you want to deactivate sensors?',
  privecy_policy: 'Privacy Policy',
  confirm_updating_devices_server: 'Confirm updating devices server',
  confirm_updating_devices_as_version:
    'Confirm updating devices as "{version} version"',
  get_scheduler: 'Get Scheduler',
  discard: 'Discard',
  edit: 'Edit',
  deactivate: 'Deactivate',
  no_leak: 'No Leak',
  suspected_fast_developing_leak: 'Suspected fast developing leak',
  suspected_leak: 'Suspected Leak',
  uncertain: 'Uncertain',
  undefined: 'Undefined',
  hydrant: 'Hydrant',
  sample_intensity: 'Sample Intensity',
  weighted_intensity: 'Weighted Intensity',
  low_rm: 'low RM: [{rm}]',
  prob: 'Prob',
  show_in_5g: 'Show in 5G',
  draw_sensors_selection_area: 'Draw sensors selection area',
  sensors_selected: '{num} Sensors Selected',
  last_sample: 'Last Sample:',
  couple_length_units: 'Couple Length: {coupleLength}[{units}]',
  diameter_or_placement_year: 'Diameter / Placement Year',
  disclaimer: 'Disclaimer',
  distance: 'Distance:',
  distance_between_sensors: 'Distance Between Sensors:',
  distance_calculation: 'Distance Calculation:',
  distance_from_sensor1: 'Distance From Sensor1:',
  distances: 'Distances',
  diameter_inch: 'Diameter Inch',
  distribution_list: 'Distribution List:',
  download_failed_file_not_found: 'Download failed - file not found',
  download_sensor_audio: 'Download Sensor Audio',
  ductile_iron: 'Ductile Iron',
  end_user: 'END USER',
  enter_an_mfa_code_to_complete_signin:
    'Enter an MFA code to complete sign-in.',
  expand_row: 'expand row',
  export2: 'Export',
  export_the_view_to_PDF: 'Export the view to PDF',
  epsg: 'EPSG',
  edit_distributorname_projects: 'Edit {distributorName} Projects:',

  end_frequency: 'End Frequency',
  end_frequency_hz: 'End Frequency(Hz)',
  end_frequncy: 'End Frequncy',
  end_record_time: 'End Record Time',
  end_time: 'End Time',
  estimated_address: 'Estimated Address',
  event_cause: 'Event Cause',
  event_date: 'Event Date',
  event_information: 'Event Information',
  event_max_value: 'Event Max Value',
  event_min_value: 'Event Min Value',
  event_nature: 'Event Nature',
  exclude_sample_1_by_4_flag: 'Exclude Sample 1 by 4 flag',
  exclude_sample_1_by_4_number: 'Exclude Sample 1 by 4 number',
  exclude_sample_1_by_4_radius: 'Exclude Sample 1 by 4 radius',

  export_all_projects_to_excel: 'Export All Projects To Excel',

  iq100b_reports: 'IQ100B Reports',
  export_the_view_to_pdf: 'Export the view to PDF',
  export: 'Export',
  ftpend_send_time: 'FTPEndSendTime',
  ftpfile_size: process.env.REACT_APP_FTP_SIZE_TEXT,
  file_size: 'File Size',
  ftpsend_duration_sec: 'FTPSendDurationSec',
  ftpstart_send_time: 'FTPStartSendTime',
  fading_alert: 'Fading alert',
  false_path: 'False Path',
  fast_development: 'Fast Development',
  file_name: 'File Name',
  filtered_avg: 'Filtered Avg.',
  filtered_intensity_avg: 'Filtered Intensity Avg',
  filtered_noise: 'Filtered Noise',
  filtered_audio_recordings_intensity_calculations_avarage:
    'Filtered audio recordings intensity calculations avarage',
  filters_: 'Filters:',
  firmware_version: 'Firmware Version',
  first_name: 'First Name',
  fixed_leaks: 'Fixed Leaks',
  flow_flow: 'Flow: {flow}',
  fmax: 'Fmax',
  fragmented_file: 'Fragmented File',
  frequency_histogram: 'Frequency Histogram',
  failed: 'Failed',
  fees: 'Fees',
  files: 'Files',
  filter: 'Filter',
  filters: 'Filters',
  first_installation_date: 'First Installation Date',
  fm2_receiver: 'FM2 Receiver',
  fm_receiver: 'FM Receiver',
  fmin: 'fmin',
  frequency: 'Frequency',
  from: 'From:',
  from_to: 'From - To',
  frost_depth: 'Frost Depth',
  fs: 'fs',
  go: 'GO',
  gps_ratio: 'GPS Ratio',
  generate: 'Generate',
  good_status: 'Good status',
  fast_growing_alert: 'Fast growing alert',
  slow_growing_alert: 'Slow growing alert',
  guidelines_: 'Guidelines:',
  hardware_version: 'Hardware Version',
  high_severity_more_than_4_leaks: 'High Severity (more than 4 leaks)',
  high_frq: 'High Frq',
  mid_frq: 'Mid Frq',
  low_frq: 'Low Frq',
  inactive: 'Inactive',
  index_band_to_send: 'Index Band To Send',
  index_: 'Index: ',
  infrastructure: 'Infrastructure',
  install_date: 'Install Date',
  installation_point_comment: 'Installation Point Comment',
  intensity_not_filtered: 'Intensity (Not Filtered)',
  intensity_: 'Intensity: ',
  intensity_intensity: 'Intensity: {intensity}',
  area_of_interest_comment: 'Area Of Interest Comment',
  area_of_interest_id_id: 'Area Of Interest {id}',
  area_of_interest_type: 'Area Of Interest Type',
  area_of_interest: 'Area Of Interest',
  areas_of_interest: 'Areas Of Interest',
  edit_area_of_interest: 'Edit Area Of Interest',
  is_csq_ok: 'Is Csq Ok',
  is_display_city_column: 'Is Display City Column',
  is_elecrolysis: 'Is Elecrolysis',
  is_ftp_ok: process.env.REACT_APP_FTP_TEXT,
  is_modem_closed_ok: 'Is Modem Closed Ok',
  is_send_file_ok: 'Is Sent File Ok',
  is_ok: 'Is OK',
  log: 'LOG',
  last_event_date: 'Last Event Date',
  last_name: 'Last Name',
  last_run_days: 'Last Run (days)',
  last_sample_: 'Last Sample: ',
  last_uninstall_reason: 'Last Uninstall Reason',
  lasteventfrequency: 'LastEventFrequency',
  leak_per_units: 'Leak per {units}',
  leakgrowth: 'LeakGrowth',
  leakperkm: 'LeakPerKm',
  leaks_infrastructure: 'Leaks / Infrastructure',
  leaks_by_diameter: 'Leaks by Diameter',
  leaks_by_materials_diameter_for_pipe_placement_year:
    'Leaks by Materials & Diameter for Pipe Placement Year',
  leaks_by_materials_diameter_vs_pipe_placement_year:
    'Leaks by Materials & Diameter vs Pipe Placement Year',
  leaks_by_materials: 'Leaks by Materials',
  leaks_by_pipe_placement_year: 'Leaks by Pipe Placement Year',
  leaks_by_years: 'Leaks by Years',
  leaks: 'Leaks',
  legend: 'Legend',
  linked_couples: 'Linked Couples',
  list_name: 'List Name',
  log_information_device_deviceid: 'Log Information. Device: {deviceId}',
  low_severity_1_leak: 'Low Severity (1 leak)',
  low_severity_with_fast_development_alert:
    'Low Severity with Fast Development Alert',
  mail: 'Mail',
  main: 'Main',
  manage_distributor: 'Manage Distributor',
  manual_reset_at_lastmanualresetdate: 'Manual Reset At {lastManualResetDate}',
  manual_reset_count_manualresetcount: 'Manual Reset Count {manualResetCount}',
  manual: 'Manual',
  map_alerts: 'Map Alerts',
  map_pipes_diameter_type: 'Map Pipes Diameter Type',
  material_diameter_placement_year: 'Material & Diameter / Placement Year',
  material_placement_year: 'Material / Placement Year',
  material_name: 'Material Name',
  medium_severity_2_4_leaks: 'Medium Severity (2 - 4 leaks)',
  meteric_feet_epsg_only: 'Meteric / Feet EPSG Only',
  min_nc1_days: 'Min Nc1 Days',
  min_noise: 'Min Noise',
  min_rms_nc1: 'Min RMS NC1',
  minimal_intensity: 'Minimal Intensity',
  minimal_noise_sampling_taken_by_the_sensor_prior_to_audio_recording:
    'Minimal noise sampling taken by the sensor prior to audio recording',
  modem_atenna_signal_strength: 'Modem Atenna Signal Strength',
  modem_usage: 'Modem Usage',
  more_samples: 'More Samples',
  most_problematic_pipes_based_on_leaks_count:
    'Most Problematic Pipes Based on Leaks Count',
  most_problematic_pipes_based_on_leaks_per_units:
    'Most Problematic Pipes Based on Leaks per {units}',
  multi_diameter: 'Multi Diameter',
  multi_material: 'Multi Material',
  nc3_std_mean_ratio: 'NC3 Std Mean Ratio',
  nc3_proj_mean: 'NC3 proj Mean',
  name_: 'Name: ',
  new_customer: 'New Customer',
  new_list_name: 'New List Name',
  new_project: 'New Project',
  no_leaks: 'No Leaks',
  nois_eaudio_duration_ms: 'Nois eAudio Duration MS',
  noise_alert_const_prob_thr: 'Noise Alert Const Prob Thr',
  noise_alert_step_prob_thr: 'Noise Alert Step Prob Thr',
  noise_audio_fs: 'Noise Audio FS',
  noise_audio_gain: 'Noise Audio Gain',
  noise_intensity_gain: 'Noise Intensity Gain',
  noise_ok_threshold: 'Noise Ok Threshold',
  noise_sample: 'Noise Sample',
  noise_samples: 'Noise Samples',
  noise_sync: 'Noise Sync',
  noise_level_: 'Noise level',
  noise_sampling_taken_by_the_sensor_during_audio_recording:
    'Noise sampling taken by the sensor during audio recording',
  noise_value_val: 'Noise value: {val}',
  non_associated: 'Non associated',
  not_active: 'Not Active',
  not_installed: 'Not Installed',
  num_coefficients: 'Num Coefficients',
  num_frequency_bands: 'Num Frequency Bands',
  numbadburstsamples_thr: 'NumBadBurstSamples_thr',
  number_of_samples_: 'Number of Samples:',
  number_of_transients: 'Number of transients',
  open_assessment: 'Open Assessment',
  open_new_leak_on_0_sample: 'Open New Leak On 0 sample',
  open_projects: 'Open Projects',
  open_reports: 'Open AQS Reports',
  open: 'Open',
  overground_acc_gps: 'Overground Acc GPS',
  overground_acc_rf: 'Overground Acc RF',
  overground_hyd_gps: 'Overground Hyd GPS',
  overground_hyd_rf: 'Overground Hyd RF',
  phone: 'Phone',
  pipe_accuracy: 'Pipe Accuracy',
  pipe_assessment_report: 'Pipe Assessment Report',
  pipe_assessment: 'Pipe Assessment',
  pipe_assessments_report_projectname_epsg:
    'Pipe Assessments Report. {projectName} [EPSG: {epsg}]',
  pipe_assessments: 'Pipe Assessments',
  pipe_condition_analyzer: 'Pipe Condition Analyzer',
  pipe_length_: 'Pipe length',
  pipe_: 'Pipe: ',
  pipes_by_assessment: 'Pipes By Assessment',
  pipes_by_presure: 'Pipes By Presure',
  pipes_high_severity_analysis: 'Pipes High Severity analysis:',
  pipes_by_placement_year_: 'Pipes by Placement Year',
  pipes_by_diameter_: 'Pipes by diameter',
  pipes_by_materials: 'Pipes by materials',
  pipes_by_presure_: 'Pipes by presure',
  pipes_severity_score: 'Pipes severity score',
  pipes: 'Pipes',
  placement_year: 'Placement Year',
  polyethelen: 'PolyEthelen',
  polygon: 'Polygon',
  poor_condition_pipe: 'Poor Condition Pipe',
  position_: 'Position: ',
  possible_pumps: 'Possible Pumps',
  pump: 'Pump',
  prv: 'PRV',
  washout: 'Washout',
  watering: 'Watering',
  fountain: 'Fountain',
  possible_ufr: 'Possible UFR',
  pressure_value_coefficient: 'Pressure Value Coefficient',
  pressure_y_value_coefficient: 'Pressure Y Value Coefficient',
  print: 'Print',
  prob_irrig: 'P.I.',
  prob4_burst: 'Prob4 Burst',
  prob4_consumption: 'Prob4 Consumption',
  processing: 'Processing',
  profiler: 'Profiler',
  proj_int_factor: 'Proj Int Factor',
  proj_int_len_factor: 'Proj Int Len Factor',
  project_name: 'Project Name',
  project_name_: 'Project: {name}',
  projects_coverage: 'Projects Coverage',
  projects: 'Projects',
  quality_1: 'Quality 1',
  quality_2: 'Quality 2',
  quality_: 'Quality: ',
  quiality_1: 'Quiality 1',
  quiality_2: 'Quiality 2',
  quiality: 'Quiality',

  g5_devices: 'G5 Devices',
  g5_devices_table: 'g5 devices table',
  g5_logs_table: 'g5 logs table',
  general: 'General',
  general_description: 'General Description',
  general_information: 'General Information',
  general_provisions: 'General Provisions',
  generate_report: 'Generate Report',
  generation: 'Generation',
  get_project_defaults: 'Get Project Defaults',
  grant_of_rights: 'Grant of Rights',
  graph: 'Graph',
  ground_type: 'Ground Type',
  guidelines: 'Guidelines:',
  hidden_div_for_generate_plotly_image: 'HIDDEN DIV FOR GENERATE PLOTLY IMAGE',
  historical_information: 'Historical Information',
  history: 'History',
  id: 'ID',
  id_default: 'ID (default)',
  images: 'Images',
  img: 'img',
  in_these_tos: 'in these TOS',
  include_map: 'Include map',
  indemnification: 'Indemnification',
  indemnity: 'Indemnity',
  index: 'Index',
  install: 'Install',
  install_device: 'Install Device',
  installation_point_id: 'Installation Point ID {id}',
  installation_point_id_2: 'Installation Point ID',
  installation_points: 'Installation Points',
  instance_distances: 'instance distances',
  instance_dts: 'instance dTs',
  intellectual_property_rights: 'Intellectual Property Rights',
  intensities: 'Intensities',
  diameter: 'Diameter',
  intensity_param: 'Intensity: {intensity}',
  add_alert: 'Add Alert',
  create_alert: 'Create Alert',
  audio_alert_3: 'Audio Alert 3',
  audio_base_1: 'Audio Base 1',
  audio_base_2: 'Audio Base 2',
  audio_sample: 'Audio Sample',
  audio_sample_clean: 'Audio Sample-Clean',
  audio_base_1_clean: 'Audio Base 1-Clean',
  audio_base_2_clean: 'Audio Base 2-Clean',
  audio_alert_3_clean: 'Audio Alert 3-Clean',
  time_stamp: 'Time Stamp',
  alert_3: 'Alert 3',
  base_1: 'Base 1',
  base_2: 'Base 2',
  event_rms: 'Event RMS',
  base_mean: 'Base Mean',
  base_std: 'Base STD',
  samples_mean: 'Samples Mean',
  samples_std: 'Samples STD',
  event_gain: 'Event Gain',
  samples_gain: 'Samples Gain',
  base_magnitude: 'Base Magnitude',
  samples_magnitude: 'Samples Magnitude',
  factor_baseline_rms: 'Factor RMS',
  alert_samples: 'Alert Samples',
  sensor_history: 'Sensor\'s History',
  sample_distance: 'Sample Distance',
  sample_distances: 'Sample Distances',
  weighted_distance: 'Weighted Distance',
  peak_0: 'Peak 0 [{ndx}, {maxProb}]',
  peak_1: 'Peak 1 [{ndx}, {maxProb}]',
  peak_2: 'Peak 2 [{ndx}, {maxProb}]',
  send_scheduler_message: 'Send Scheduler Message',
  type_param: 'Type: {type}',
  type: 'Type',
  assessment_param: 'Assessment ID: {id}',
  area_of_interest_id: 'Area of Interest {id}',
  users_list: 'Users List',
  select_all_users: 'Select All Users',
  create_report: 'Create Report',
  save_as_pdf: 'Save as PDF',
  iq_100b_mobile: 'IQ100B - Mobile',
  device_id_param: 'Device ID: {device}',
  detected_date_date: 'Detected Date: {date}',
  pipe_install: 'Pipe Install',
  closure_date_date: 'Closure Date: {date}',
  fixed_date_date: 'Fixed Date: {date}',
  event_date_param: 'Event Date: {date}',
  status_state_param: 'Status: {state}',
  status_param: 'Status: {status}',
  alert_type_param: 'Alert Type: {alertType}',
  noise_alert_id_noiseId: 'Noise Alert ID: {noiseId}',
  comment_comment: 'Comment: {comment}',
  end_date: 'End Date',
  couple_id_param: 'Couple ID: {id}',
  probability_param: 'Probability: {probability}',
  leak_boss_param: 'Leak Boss: {boss}',
  intensity2: 'Intensity:',
  interferences: 'Interferences',
  interference_artifact: 'Interference Artifact',
  is_electrolysis: 'Is Electrolysis',
  is_pressure: 'Is Pressure',
  is_mini: 'Is G5mini',
  is_previous_break_reported: 'Is Previous Break Reported',
  is_rocks: 'Is Rocks',
  is_voids: 'Is Voids',
  items: 'Items',

  lamppost_number: 'Lamppost Number',
  large_diameter: 'Large Diameter',
  last_comment: 'Last Comment:',
  last_communication: 'Last Communication',
  last_event_time: 'Last Event Time',
  last_installation_date: 'Last Installation Date',
  latitude: 'Latitude',
  launch_street_view_for_selected_item: 'Launch Street View for selected item',
  lead: 'Lead',
  leak_growth: 'Leak Growth',
  leak_id: 'Leak ID',
  leak_id2: 'Leak ID:',
  length: 'Length',
  license: 'License',
  limitation_of_liability: 'Limitation of Liability',
  limitation_of_liability2: 'LIMITATION OF LIABILITY',
  limitations: 'Limitations',
  limitations_on_use: 'Limitations on Use',
  loading: 'Loading...',
  location_information: 'Location Information',
  logs: 'logs',
  longitude: 'Longitude',
  longitude_latitude: 'Longitude, Latitude:',
  mail_sms_notification: 'Mail / SMS Notification',
  manage_distribution_lists: 'Manage Distribution Lists',
  management: 'Management',
  map_could_not_be_captured_please_take_a_screenshot:
    'Map could not be captured. Please take a screenshot manually.',
  material: 'Material',
  material_and_diameter_or_placement_year:
    'Material & Diameter / Placement Year',
  material_or_placement_year: 'Material / Placement Year',
  materials: 'Materials',
  max_probability: 'MAX Probability',
  mdpe: 'MDPE',
  mfa_enabled: 'MFA Enabled',
  metal: 'Metal',
  mfa_code: 'MFA Code:',
  mobile: 'Mobile',
  modification_of_tos: 'Modification of TOS',
  month: 'Month',
  multifactor_authentication: 'Multi-factor Authentication',
  n: 'N',
  min_value: 'Min Value',
  max_value: 'Max Value',
  median_value: 'Median Value',
  name: 'Name',
  name_correlation: '{name} Correlation',
  nature_of_application_hosting: 'Nature of Application &amp; Hosting',
  nature_of_break_comment: 'Nature of Break Comment',
  network_coverage_gnss_satellites_interruption_of_s:
    'Network Coverage, GNSS Satellites, Interruption of Service',
  new_couple: 'New Couple',
  new_list_item: 'New List Item',
  no_data: 'NO DATA',
  no_files_uploaded_yet: 'No files uploaded yet...',
  no_filters_applyed: 'No filters applyed',
  no_lists: 'No Lists',
  noise_NC1_NC2: 'Noise NC1/NC2',
  noise_alert_options: 'Noise Alert Options',
  noise_alert_statuses: 'Noise Alert Statuses',
  noise_interval: 'Noise Interval',
  noise_level: 'Noise Level',
  valve_alert_options: 'Valve Alert Options',
  control_valve: 'Control Valve',
  service_valve: 'Service Valve',
  gate_valve: 'Gate Valve',
  air_valve: 'Air Valve',
  manhole: 'Manhole',
  fitting: 'Fitting',
  signals: 'Signals',
  tensors: 'Tensors',
  previous_samples: 'Previous Samples',
  fmReceiverSN: 'Fm Receiver SN',
  hardwareRevision: 'HardwareRevision',
  domainName: 'DomainName',
  at_least_length_characters_are_required:
    'At least {length} characters are required',
  at_least_one_uppercase_letter: 'Require Uppercase Letters',
  at_least_one_lowercase_letter: 'Require Lowercase Letters',
  at_least_one_number: 'Require Numbers',
  the_passwords_are_not_the_same: 'The passwords are not the same',
  invalid_password: 'Invalid Password',
  back_to_signin_page: 'Back to Sign-in page',
  forgot_your_password: 'Forgot your password?',
  email_to_reset_password:
    'Enter your Email below and we will send a message to reset your password',
  email_to_reset_password_sent:
    'We have sent a password reset code by Email to {mail}. Enter it below to reset your password.',
  reset_my_password: 'Reset my password',
  new_password: 'New Password',
  confirm_new_password: 'Enter New Password Again',
  change_password: 'Change Password',
  alert_information: 'Alert Information',
  created_date: 'Created Date',
  raw_intensity: 'Raw Intensity',
  filtered_intensity: 'Filtered Intensity',
  consistency: 'Consistency',
  pi: 'P.I',
  close_alert: 'Close Alert',
  number_of_samples: 'Number of Samples',
  load: 'Load',
  clean_sample: 'Clean Sample',
  sample: 'Sample',
  device_type: 'Device Type: {deviceType}',
  address_param: 'Address: {address}',
  reset_password: 'RESET PASSWORD',
  rf_sync_problem_: 'RF Sync Problem, ',
  rsrp_dbm: 'RSRP [dbm]',
  radio_: 'Radio ',
  radio_and_audio_window_sec: 'Radio And Audio Window Sec',
  radio_dt_dt_quality_quality: 'Radio [dt: {dt}, quality: {quality}]',
  ready: 'Ready',
  record_duration: 'Record Duration',
  recording_duration_sec: 'Recording Duration [Sec]',
  recording_durationsec: 'Recording DurationSec',
  recordingtime_1: 'RecordingTime 1',
  recordingtime_2: 'RecordingTime 2',
  recordingtime_3: 'RecordingTime 3',
  registered_microsoft_partner: 'Registered Microsoft Partner',
  relative_sensor: 'Relative sensor',
  reload_data: 'Reload data',
  remember_me: 'Remember me',
  remove_sensor_sensorid: 'Remove Sensor {sensorID}',
  remove: 'Remove',
  repair_date_: 'Repair date',
  report_enabled: 'Report Enabled',
  reports_settings: 'Reports Settings',
  reports: 'Reports',
  resets: 'Resets',
  sms: 'SMS',
  sop_id_: 'SOP ID: ',
  sop_status: 'SOP Status',
  sop_type: 'SOP Type',
  sops_filters: 'SOPs Filters',
  sample_correlation_contrast: 'Sample Correlation Contrast',
  sample_correlation: 'Sample Correlation',
  sample_id_: 'Sample ID: ',
  sample_noise: 'Sample Noise',
  sample_size: 'Sample Size',
  sample_spectrogram: 'Sample Spectrogram',
  sample_time_: 'Sample Time: ',
  sampled_intensity: 'Sampled Intensity',
  samples2: 'Samples2',
  sampling_frequency: 'Sampling Frequency',
  save_this_couple_assessment_report: 'Save this couple-assessment-report',
  save_this_work_order: 'Save this work-order',
  second_sensor: 'Second sensor',
  select_projects: 'Select Projects...',
  select_report: 'Select Report...',
  select_project: 'Select project',
  select_: 'Select...',
  selected_selectedindex_total_total:
    'Selected: {selectedIndex}. Total: {total}',
  send_to_printer: 'Send to printer',
  sensitivity_level: 'Sensitivity Level',
  sensor_id_: 'Sensor ID: ',
  sensor_id_id: 'Sensor ID: {id}',
  sensor_type: 'Sensor Type',
  sensor_comment_: 'Sensor comment',
  sensor_id_default: 'Sensor id (default)',
  sensor: 'Sensor',
  sensors_report: 'Sensors Report',
  sensors_filters: 'Sensors Filters',
  sensors_options: 'Sensors Options',
  service_provider_: 'Service provider',
  settings: 'Settings',
  shadow: 'Shadow',
  show_hide: 'Show / Hide',
  show_all: 'Show All',
  show_iquarius_samples: 'Show iQuarius Samples',
  sim_number: 'Sim Number',
  sopid: 'SopID',
  source_alert: 'Source Alert',
  start_frequency: 'Start Frequency',
  start_frequency_hz: 'Start Frequency(Hz)',
  start_time: 'Start Time',
  state: 'State',
  status_default: 'Status (default)',
  percent: 'Percent',
  total_count: 'Total Count',
  under_count: 'Under Count',
  over_count: 'Over Count',
  mixed_over_under_count: 'Mixed Type Count',
  g5_count: 'G5 Count',
  g4_count: 'G4 Count',
  mixed_g_count: 'Mixed G Count',
  project_id: 'Project ID',
  project_display_name: 'Project Display Name',
  status: 'Status: ',
  sudden_air_temp_change: 'Sudden Air Temp Change',
  task_id: 'Task Id',
  time_: 'Time: ',
  timezone: 'Timezone',
  total_length_unitslable: 'Total length [{unitsLable}]',
  type_: 'Type: ',
  type_type: 'Type: {type}',
  under: 'Under',
  underground_acc_gps: 'Underground Acc GPS',
  underground_acc_rf: 'Underground Acc RF',
  underground_hyd_gps: 'Underground Hyd GPS',
  underground_hyd_rf: 'Underground Hyd RF',
  uninstall_device_and_remove_sensor: 'Uninstall Device And Remove Sensor',
  uninstall_device_and_install_it_on_the_new_sensor:
    'Uninstall Device and Install it on the new Sensor',
  uninstall_comment: 'Uninstall comment',
  uninstall: 'Uninstall',
  uninstalled: 'Uninstalled',
  update_customer_details: 'Update Customer Details',
  update_pipes: 'Update Pipes',
  update_project_details: 'Update Project Details',
  update_user_details: 'Update User Details',
  use_feet_in_matlab_graphs: 'Use Feet In Matlab Graphs',
  use_imperial_units_in_graphs: 'Use Imperial units in Graphs',
  user_name_name: 'User: {name}',
  users: 'Users',
  w: 'W',
  water_loss_unith: 'Water Loss ({unit}/h)',
  weve_sent_a_message_to_email__please_check_your_inbox:
    "We've sent a message to {email} \n Please check your inbox.",
  work_area_: 'Work area',
  your_item_located_in_project: 'Your {item} is located in project {project}.',
  display_item: 'Display {item}',
  hz: '[Hz]',
  unitshz: ' [{units}/Hz]',
  balagan_dist_ratio_thr: 'balagan_dist_ratio_thr',
  couple: 'couple',
  false: 'false',
  first: 'first',
  ft: 'ft',
  inactive_couple_no_sensors_planning:
    'inactive couple - no sensors - planning',
  inactive_couple_no_sensors: 'inactive couple - no sensors',
  inactive_couple: 'inactive couple',
  irrig_dist: 'irrig_dist',
  last: 'last',
  length_length_units: 'length: {length}[{units}]',
  no_correlations_warning: 'no correlations warning',
  slope_slope_std_std_r2_r2: 'slope: {slope}, std: {std}, r2: {r2}',
  sum_included_thr: 'sum_included_thr',
  update_from_aqs_mobile_details: 'update from AQS Mobile. ({details})',
  name_spectrogram: '{name} Spectrogram',
  low_rm_rm: 'low RM: [{rm}]',
  noise_num_samples: 'Noise Num Samples',
  noise_tx_time: 'Noise Tx Time',
  average_last: 'Average Last',
  none: 'None',
  not_found: 'Not Found',
  not_include_not_installed_sensors: "* Not Include 'Not Installed' sensors",
  notices_and_electronic_communications:
    'Notices and Electronic Communications',
  official_language: 'Official Language',
  op_audio_table: '{op} audio table',
  or: ' OR “',
  original_audio: 'Original Audio',
  other: 'Other',
  overground: 'Overground',
  override_length: 'Override Length',
  p: 'P',
  payload: 'Payload',
  payment: 'Payment',
  pca: 'PCA',

  sample_images: 'Sample Images',
  user_sample_classify: 'classify',
  invalid_email_address: 'Invalid email address',
  phone_number: 'Phone Number',
  pictures: 'pictures',
  pipe: 'Pipe:',
  pipe_coating: 'Pipe Coating',
  pipe_condition_assessment_details: 'Pipe Condition Assessment Details',
  pipe_condition_details: 'Pipe Condition Details',
  pipe_diameter: 'Pipe Diameter',
  pipe_id: 'Pipe ID',
  pipe_install_year: 'Pipe Install Year',
  pipe_length: 'Pipe Length',
  pipe_length2: 'Pipe length:',
  filter_by_customer: 'Filter by Customer',
  check_devices: 'Check Devices',
  copy_device_id_from_excel: 'Copy Devices ID from Excel',
  count_devices: '{count} Devices',
  selected_devices: '{selected} / {count} Devices',
  pipe_material: 'Pipe Material',
  pipe_section_details: 'Pipe Section Details',
  pipe_thickness: 'Pipe Thickness',
  pipe_thickness_at_break: 'Pipe Thickness (at break)',
  pipe_type: 'Pipe Type',
  pipes_materials: 'Pipes materials:',
  please_uninstall_device: 'Please Uninstall Device',
  point_1: 'Point 1',
  point_2: 'Point 2',
  poly_ethelen: 'Polyethelen',
  position: 'Position:',
  preparing_to_download_if_download_does_not_commence_please_allow_popups_for_this_site:
    'Preparing to download. If download does not commence, please allow popups for this site.',
  previous_break_operating_press: 'Previous Break Operating Press',
  prior_2_weeks_weather: 'Prior 2 Weeks Weather',
  priority: 'Priority',
  intensity_opr: 'Intensity Opr',
  probability_opr: 'Probability Opr',
  work_area: 'Work Area',
  repair_code: 'Repair Code',
  contractor_id: 'Contractor Id',
  detected_at: 'Detected At',
  priority_default: 'Priority (default)',
  privacy_statement: 'Privacy Statement',
  probability: 'Probability',
  processing_of_personal_data: 'Processing of Personal Data',
  products: 'Products',
  project: 'Project',
  project2: 'Project:',
  proximity_to_other_utilities: 'Proximity to Other Utilities',
  prs_calib_multiply: 'PRS Calib Multiply',
  prs_calib_shift: 'PRS Calib Shift',
  pvc: 'PVC',
  quality: 'Quality:',
  radio: 'Radio',
  radio_frequency: 'Radio Frequency',
  ref_time: 'Ref Time',
  registration_payment: 'Registration; Payment',
  remove_filters_to_view_device: 'Remove Filters To view device',
  item_is_hidden_by_filters:
    '{item} is hidden by filters',
  remove_sensor: 'Remove Sensor',
  repair_date: 'Repair date',
  repair_details: 'Repair Details',
  add_new_alert: 'Add New Alert',
  add_new_mobile_alert: 'Add New Mobile Alert',
  repair_type: 'Repair Type',
  detection_date: 'Detection Date',
  report: 'Report',
  reported_by: 'Reported By',
  reset: 'Reset',
  resistivity: 'Resistivity',
  restrictions_no_misuse_of_services: 'Restrictions - No Misuse of Services',
  result: 'Result',
  s: 'S',
  sample_id: 'Sample ID:',
  sample_time: 'Sample Time:',
  sample_time_1: 'Sample Time 1',
  sample_time_2: 'Sample Time 2',
  sample_time_3: 'Sample Time 3',
  samples: 'Samples',
  sap_number: 'SAP Number:',
  save: 'Save',
  save_and_apply: 'Save & Apply',
  save_as_excel: 'Save as Excel',
  save_changes: 'Save Changes',
  scheduler: 'Scheduler',
  search: 'Search',
  search_box: 'Search Box',
  sec: 'sec',
  select: 'Select...',
  select2: 'Select',
  select_draw_mode_and_draw_area_of_interest:
    'Select draw mode and draw Area Of Interest',
  select_sensor: 'Select Sensor',
  selected_param: 'Selected: {selectedItems}',
  selected_sensor_id: 'Selected Sensor {id}',
  sensor_id_param2: 'Sensor: {id}',
  send: 'Send',
  sensor1_address: 'Sensor1 Address:',
  bands: 'Bands',
  days: 'Days',
  avg_max_leak_val_ndx:
    'AVG Max Leak [ndx: {maxProbIndex}, leak ndx: {maxProbLeakIndex}, val: {maxProbVal}]',
  sensor2_address: 'Sensor2 Address:',
  sensor_activity_threshold: 'Sensor Activity Threshold',
  sensor_comment: 'Sensor Comment',
  wake_up_before_radio: 'Wake Up Before Radio',
  random_delay: 'Random Delay',
  sampling_freq: 'Sampling Freq',
  sensor_id: 'Sensor ID',
  sensor_id2: 'Sensor ID:',
  sensor_id_param: 'Sensor ID: {id}',
  sensor_install: 'Sensor Install',
  sensor_is_not_found_check_filters_and_try_again:
    'Sensor is not found. check filters and try again',
  sensors: 'Sensors',
  sensors_nc_table: 'sensors nc table',
  service: 'Service',
  service_provider: 'Service provider',
  service_provider2: 'Service Provider',
  service_subscription: 'Service Subscription',
  session_max_value: 'Session Max Value',
  session_min_value: 'Session Min Value',
  session_num_events: 'Session Num Events',
  set_actual_leak_position: 'Set Actual Leak Position',
  set_actual_leak_position_on_map: 'Set actual leak position on Map:',
  set_apn: 'Set APN',
  set_leak_valve_monitor: 'Set Leak Valve Monitor',
  event_magnitude_constant: 'Event Magnitude',
  magnitude_threshold: 'Magn. Threshold',
  leak_monitor_interval_sec: 'Monitor Interval Sec',
  leak_monitor_alert_suspend_interval_sec: 'Suspend Interval Sec',
  sample_frequency_hz: 'Sample Freq. HZ',
  leak_monitor_long_sample_sec: 'Sample Duration',
  sample_duration: 'Sample Duration',
  notch_filter: 'Notch Filter',
  compress_enabled: 'Compress Enabled',
  channel_10k: 'Channel 10k',
  radio_band_mask_1: 'Radio Band Mask 1',
  radio_band_mask_2: 'Radio Band Mask 2',
  radio_band_mask_3: 'Radio Band Mask 3',
  radio_band_mask_4: 'Radio Band Mask 4',
  audio_duration: 'Audio Duration',
  mode: 'Mode',
  cat_m_preferred: 'CAT-M preferred',
  nb_iot_preferred: 'NB-IOT preferred',
  cat_m_only: 'CAT-M Only',
  zone: 'Zone',
  threshold_3: 'Threshold 3',
  threshold_2: 'Threshold 2',
  threshold_1: 'Threshold 1',
  auto_gain: 'Auto Gain',
  gain_1: 'Gain 1',
  gain_2: 'Gain 2',
  gain_3: 'Gain 3',
  gain_4: 'Gain 4',
  nb_iot_only: 'NB-IOT Only',
  channel_num: 'Channel {num}',
  radio_duration: 'Radio Duration',
  audio_fs: 'Audio FS',
  sampling_window: 'Sampling Window',
  audio_num_bands: 'Audio Num Bands',
  audio_num_coff: 'Audio Num Coff',
  audio_band_mask_1: 'Audio Band Mask 1',
  audio_band_mask_2: 'Audio Band Mask 2',
  audio_band_mask_3: 'Audio Band Mask 3',
  audio_band_mask_4: 'Audio Band Mask 4',
  radio_num_bands: 'Radio Num Bands',
  radio_num_coff: 'Radio Num Coff',
  audio_enable: 'Audio Enable',
  conf: 'Conf',
  fm2_conf: 'fm2 conf',
  fm1: 'fm1',
  fm2: 'fm2',
  corr_sample_freq: 'corr_sample_freq',
  audio_report_enabled: 'audio_report_enabled',
  corr_radio_duration: 'corr_radio_duration',
  corr_audio_duration: 'corr_audio_duration',
  noise_audio_duration: 'noise_audio_duration',
  corr_audio_fs: 'corr_audio_fs',
  wakeup_befor: 'wakeup_befor',
  random_backoff_connect: 'random_backoff_connect',
  dfu_fw: 'dfu_fw',
  dfu_type: 'dfu_type',
  dfu_block: 'dfu_block',
  modem_fw: 'Modem fw',
  snr1: 'snr1',
  snr2: 'snr2',
  last_updated: 'Last Updated',
  tlv_server_time: 'TLV Server Time',
  cellular_time: 'Cellular Time',
  apn: 'APN',
  domain: 'Domain',
  modem_version: 'Modem Version',
  search_users: 'Search Users',
  search_alert: 'Search Alert',
  search_sensor: 'Search Sensor',
  model: 'Model',
  iemi: 'IMEI',
  iccid: 'ICCID',
  search_installation_point: 'Search Installation Point',
  search_interference: 'Search Interference',
  interference_id: 'Interference ID',
  set_default: 'Set Default',
  prev: 'Prev',
  next: 'Next',
  required: 'Required',
  search_couple: 'Search Couple',
  continue: 'Continue',
  you_are_about_to_take_a_significant_action_on_num_devices:
    'YOU ARE ABOUT TO TAKE A SIGNIFICANT ACTION ON {num} DEVICES:',
  confirm_updating_devices_apn_to: 'Confirm updating devices APN to "{apn}"',
  search_task: 'Search Task',
  lte_mode: 'LTE Mode',
  confirm_updating_lte_mode_to: 'Confirm updating LTE mode to "{mode}"',
  search_user: 'Search User',
  search_project: 'Search Project',
  search_customer: 'Search Customer',
  filter_by: 'Filter by',
  filter_by_project: 'Filter by Project',
  filter_by_customer: 'Filter by Customer',
  set_capability: 'Set Capability',
  set_fm_scan: 'Set FM Scan',
  set_lte_configuration: 'Set LTE Configuration',
  set_lte_mode: 'Set LTE Mode',
  set_secondary_server: 'Set Secondary Server',
  low_frequency: 'Low Frequency',
  high_frequency: 'High Frequency',
  medium_frequency: 'Medium Frequency',
  secondary: 'secondary',
  boundaries: 'Boundaries',
  server: 'server',
  set_server: 'Set Server',
  set_time_source: 'Set Time Source',
  set_tpcip_config: 'Set TPC-IP Config',
  severity: 'Severity:',
  show_av: 'Show AV',
  site_access_password_security: 'Site Access &amp; Password; Security',
  slope_std_r2: 'slope: {slope}, std: {std}, r2: {r2}',
  snow_depth: 'Snow Depth',
  sop_id: 'SOP ID:',
  sops: 'SOPs',
  sort_by: 'Sort by',
  source: 'Source',
  status: 'Status',
  stolen: 'Stolen',
  street_surface: 'Street Surface',
  submit: 'Submit',
  subscriptions_and_licenses_restrictions:
    'Subscriptions and Licenses; Restrictions',
  success: 'Success',
  sudden_water_temp_change: 'Sudden Water Temp Change',
  supervisor_user: 'Supervisor User',
  support_services: 'Support Services',
  switch_to_projectName: 'Switch to {projectName}',
  switch_to_project: 'Switch to {project}',
  switch: 'Switch',
  sync: 'Sync',
  system: 'System',
  tabs_container: 'tabs container',
  task_name: 'Task Name',
  alert_name: 'Alert Name',
  temp_avg: 'Temp Avg',
  temp_fail: 'Temp Fail',
  temp_rise: 'Temp Rise',
  term_and_termination: 'Term and Termination',
  terms: 'TERMS',
  text_alignment: 'text alignment',
  this_is_a_demo_404_page: 'This is a demo 404 page!',
  this_website_uses_cookies_to_enhance_the_user_expe:
    'This website uses cookies to enhance the user experience.',
  time: 'Time',
  time_limited_service: '"Time Limited Service"',
  time_limited_service_subscription: 'Time Limited Service Subscription',
  time_zone: 'Time Zone',
  to: 'To',
  to2: 'To:',
  to_businesses_and_professionals: ') to businesses and professionals.',
  total: 'Total',
  total2: 'Total:',
  traffic: 'Traffic',
  transient: 'Transient',
  trend: 'Trend',
  type: 'Type',
  u: 'U',
  under_over: 'Under / Over',
  underground: 'Underground',
  uninstall_device: 'Uninstall Device',
  update: 'Update',
  update_from_aws_mobile_details: 'Update from AQS Mobile. ({details})',
  update_modem_version: 'Update Modem Version',
  update_status_to: 'Updated Status to',
  update_version: 'Update Version',
  updated: 'Updated',
  upload_files: 'Upload Files',
  upload_screenshot: 'Upload Screenshot',
  us_government_restricted_rights: 'U.S. Government Restricted Rights',
  user: 'User',
  delete_user: 'Delete User',
  utc_time: 'UTC Time',
  v: 'V',
  v_x: 'V: {totalSampledDays}, X: {totalUnSampledDays},',
  v_x2: 'V: {totalSampledDaysWithoutEmpty}, X: {totalUnSampledDaysWithoutEmpty},',
  value: 'Value',
  valve: 'Valve',
  valve_monitoring: 'Valve Monitoring',
  velocity: 'velocity',
  version: 'Version',
  wall: 'Wall',
  g3e_fid: 'G3E FID',
  water_loss_unit_h: 'Water Loss ({unit}/h)',
  water_type: 'Water Type',
  pin_location: 'Pin Location',
  weather_conditions: 'Weather Conditions',
  week: 'Week',
  wood: 'Wood',
  work_area: 'Work area',
  wover: 'wover',
  wt: 'wt',
  x: 'X',
  x_y: 'X , Y:',
  y: 'Y',
  you: 'YOU',
  ver: 'Ver',
};
// // Get the keys and sort them
// const sortedKeys = Object.keys(en).sort();

// // Create a new object and assign the sorted keys with their respective values
// let sortedObject = {};
// for (let key of sortedKeys) {
//   sortedObject[key] = en[key];
// }

// console.log(sortedObject);
