import { connect } from 'react-redux';
import { setAudioClassification } from '../../actions/SamplesOutputDataActions';
import SampleInfo from '../../components/SampleInfo/SampleInfo';

function mapStateToProps(state, props) {
  const units = state.local.units;
  const distanceFactor = state.local.factor[units];
  const user = state.leaksList.user;

  const selectedSample = state.samples.selectedSample;
  console.log('selectedSample', selectedSample); //doesnt help


  const { audioData, probability, tensor, trend, analysisData } = state.samples;

  const graphs = state.graphs;
  // console.log('graphs state', graphs); //doesnt help
  // console.log('graphsUnits', props.graphsUnits); // prints only m
  // console.log('tensor log', tensor); //doesnt help - another tensor


  const audioClassificationsOptions = state.leaksList.optionList.options.AudioClassificationEnum;

  const distancesForAxis = graphs.distancesData.map((x) => {
    const val = (x === '') ? '' : (x * distanceFactor).toFixed();
    return val;
  });
  const sensorsForAxis = graphs.distancesDataSensorsOnly.map((x) => {
    const val = (x === '') ? '' : (x * distanceFactor).toFixed();
    return val;
  });

  return {
    ...props,
    audioData: audioData,
    selectedSample,
    distanceFactor,
    analysisData,
    probability,
    tensor,
    trend,
    rawData: graphs.rawData,
    graphParams: graphs.params,
    correlationInstance: graphs.correlationInstance,
    correlationAve: graphs.correlationAve,
    histogramInstance: graphs.histogramInstance,
    histogramAve: graphs.histogramAve,
    distancesForAxis: distancesForAxis,
    sensorsForAxis: sensorsForAxis,
    sensorsPositions: graphs.sensorsPositions,
    bias: graphs.bias,
    history: graphs.history, //.map((data) => data.histogram),
    historyNoiseData: [...graphs.historyNoiseData],
    maxProbabilityPositions: graphs.maxProbabilityPositions,
    maxProbabilityGraphs: graphs.maxProbabilityGraphs,
    audioClassificationsOptions,
    user,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    setClassificationForAudio: (classificationCode, itemId) => {
      dispatch(setAudioClassification(itemId, classificationCode));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SampleInfo);
