import React, { useEffect, useState } from 'react';

import { Table, Column } from 'fixed-data-table-2';
import TableMixin from '../../components/TableMixin/TableMixin';
import TextCell from '../../components/TextCell/TextCell';
import SortableHeaderCell from '../../components/SortableHeaderCell/SortableHeaderCell';
import CSelectCell from '../../containers/CSelectCell/CSelectCell';
import CTableActions from '../../containers/CTableActions/CTableActions';
import { DateType } from '../../containers/UIhelper/UIDateFormater';
import CMobileAlertsSettings from '../../containers/CMobileAlertsSettings/CMobileAlertsSettings';

const MobileAlertsTable = (props) => {
  const {
    items,
    indexMap,
    selectedFeature,
    pHeight,
    pWidth,
    localSortProp,
    rowClickEvent,
    rowClassNameGetter,
    goToRow,
    t,
  } = props;

  const [rowIndexArtificial, setRowIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (selectedFeature) {
        const rowIndex = indexMap[selectedFeature.alert_id];
        setRowIndex(rowIndex);
      }
    }, 1500);
  }, [selectedFeature]);

  const totalItemsStyle = {
    float: 'right',
    padding: '2px 8px',
  };

  // const customGoToRow = () => {
  //   let result = 0;

  //   if (selectedFeature) {
  //     result = indexMap[selectedFeature.alert_id];
  //   }

  //   return result;
  // };

  return (
    <div>
      <Table
        maxHeight={pHeight - 93}
        width={pWidth}
        rowsCount={items.length}
        rowHeight={30}
        headerHeight={30}
        onRowClick={rowClickEvent}
        rowClassNameGetter={rowClassNameGetter}
        scrollToRow={rowIndexArtificial}
      >
        <Column
          columnKey='alert_id'
          width={80}
          flexGrow={2}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field='alert_id' />}
        />
        <Column
          columnKey='create_date'
          width={120}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={
            <TextCell
              data={items}
              field='create_date'
              dateType={DateType.DATE_AND_TIME}
            />
          }
        />
        <Column
          columnKey='Intensity'
          width={115}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field='intensity' />}
        />

        <Column
          columnKey='AlertType'
          width={115}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={
            <CSelectCell
              data={items}
              options={props.optionList.options.AlertType}
              field='alert_type'
              contextType='mobile'
            />
          }
        />
        <Column
          columnKey='AlertState'
          width={115}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={
            <CSelectCell
              data={items}
              options={props.optionList.options.AlertStateNoise}
              field='alert_state'
              contextType='mobile'
            />
          }
        />
        <Column
          columnKey='TaskName'
          width={80}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field='task_name' />}
        />
        <Column
          columnKey='Address'
          width={80}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field='address' />}
        />
        <Column
          columnKey='Comment'
          width={80}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field='comment' />}
        />
      </Table>

      {items && items.length > 0 && (
        <CTableActions type='mobileAlerts' notify={props.notify} />
      )}
      {items && items.length > 0 && (
        <span style={totalItemsStyle}>
          {t('selected_selectedindex_total_total', {
            selectedIndex: indexMap[selectedFeature?.alert_id] + 1 || 0,
            total: items.length,
          })}
        </span>
      )}
    </div>
  );
};

export default TableMixin(MobileAlertsTable, CMobileAlertsSettings);
