import * as endPoints from '../constants/EndPoints';
import * as deviceConfActions from './DeviceConfigurationAction';
import * as globalsActions from './GlobalsActions';
import { fetchMiddleware } from './MiddlewareActions';

const _ = require('lodash');

export function searchItem(itemID, type, path, callback) {
  return (dispatch, getState) => {
    const state = getState();
    //const project = state.leaksList.selectedProject;
    //const projectState = state.leaksList.leaksByProject[project];
    const installContext = state.install.installContext;
    const alertContext = state.leaksList.pressure.context;
    let searchItemType = path === 'install' ? installContext : path;

    const feature = searchLocal(
      state,
      itemID,
      type,
      path,
      installContext,
      alertContext
    );

    if (feature != null && !_.isEmpty(feature)) {
      if (path !== 'g5Devices') {
        dispatch(globalsActions.setSelectedItem(feature));
      } else {
        const selection = state.devcieConfigurations.selection;
        if (selection.indexOf(feature.DeviceID) === -1) {
          selection.push(feature.DeviceID);
        }
        dispatch(deviceConfActions.selectDevices(selection));
      }
    } else {
      if (path === 'mobile') {
        callback(null, false);
        return;
      }

      if (path === 'alerts') {
        if (alertContext === 'noise') {
          searchItemType = 'noiseAlerts';
        } else if (alertContext === 'prsAlerts') {
          searchItemType = 'pressureAlerts';
        } else if (alertContext === 'valve') {
          searchItemType = 'valveAlerts';
        }
      }

      dispatch(searchItemInAllProjects(itemID, searchItemType, type, callback));
    }
  };
}

function searchItemInAllProjects(itemID, type, field, callback) {
  const filter = [{ field: field, value: [itemID], avoidNulls: true }];

  const path =
    endPoints.END_POINT +
    '/search/' +
    type +
    '?filters=' +
    JSON.stringify(filter);
  return (dispatch, getState) => {
    const state = getState();
    return fetchMiddleware(path, {}, getState).then((json) => {
      let iconType = 'leaks';
      switch (type) {
        case 'alert':
          iconType = 'leaks';
          break;
        case 'sensor':
          iconType = 'sensors';
          break;
        default:
          break;
      }

      //const icon = getIconFeatures(iconType, json.data);
      const status = json.status;
      const found = status && json.data[0] != null;
      let projectID = found ? json.data[0].ProjectID : null;

      if (found && type === 'noiseAlerts') {
        projectID = json.data[0].Project_id;
      };

      const projectName = found ? getProjectName(state, projectID) : null;

      const result = {
        status: json.status,
        projectID,
        projectName,
        item: json.data[0],
      };
      callback(null, result);
    });
  };
}

function getProjectName(state, projectID) {
  const projectsDetails = state.leaksList.projectsList;
  const projectName =
    projectsDetails.items[projectsDetails.projectIndexMap[projectID]].ShortName;
  return projectName;
}

function searchLocal(state, itemID, type, path, installContext, alertContext) {
  let feature;
  const project = state.leaksList.selectedProject;
  const projectState = state.leaksList.leaksByProject[project];

  switch (path) {
    case 'alerts':
      switch (alertContext) {
        case 'alerts':
          feature = getAlertByType(projectState, itemID, type);
          break;
        case 'pressure':
          feature = getPressureAlertByType(state, itemID, type);
          break;
        case 'noise':
          feature = getNoiseAlertByType(state, itemID, 'ID');
          break;
        default:
          feature = null;
          break;
      }
      break;
    case 'sensors':
      feature = getSensorByType(projectState, itemID, type);
      break;
    case 'couples':
      feature = getCoupleByType(projectState, itemID, type);
      break;
    case 'manage':
      feature = getManageItemByType(state, itemID, type);
      break;
    case 'g5Devices':
      feature = getG5DeviceByType(state, itemID, type);
      break;
    case 'install':
      switch (installContext) {
        case 'Sensors':
          feature = getSensorByType(projectState, itemID, type);
          break;
        case 'SOPs':
          feature = getSopItemByType(projectState, itemID, type);
          break;
        case 'Interferences':
          feature = getInterferenceByType(projectState, itemID, type);
          break;
        default:
          break;
      }
      break;
    case 'mobile':
      const {
        mobile: { tasks: { items } } } = state;
      const mobileFeatures = getMobileTask(items, itemID);
      feature = mobileFeatures.length > 0 ? mobileFeatures : null;
      break;
    default:
      break;
  }

  return feature;
}

export const getMobileTask = (items, searchText) => {
  return items.filter((item) =>
    item.task_name.toLowerCase().includes(searchText.toLowerCase())
  );
};

const getAlertByType = (state, itemID, type) => {
  let alert = null;
  const alerts = state.items;
  if (type === 'ID') {
    if (state.indexMap[itemID] != null) {
      alert = alerts[state.indexMap[itemID]];
    }
  }

  return alert;
};

const getPressureAlertByType = (state, itemID, type) => {
  let alert = null;
  const alerts = state.leaksList.pressure.transientAlerts.alerts;
  const indexMap = state.leaksList.pressure.transientAlerts.indexMap;
  if (type === 'ID') {
    if (indexMap[itemID] != null) {
      alert = alerts[indexMap[itemID]];
    }
  }

  return alert;
};

const getNoiseAlertByType = (state, itemID, type) => {
  let alert = null;
  const alerts = state.noiseAlerts.items;
  const indexMap = state.noiseAlerts.indexMap;
  if (type === 'ID') {
    if (indexMap[itemID] != null) {
      alert = alerts[indexMap[itemID]];
    }
  }

  return alert;
};

function getSensorByType(state, itemID, type) {
  let sensor = null;

  const sensors = state.sensors.sensors;
  if (type === 'ID') {
    const indexMap = state.sensors.indexMap;
    sensor = Object.assign({}, sensors[indexMap[itemID]]);
  } else {
    for (let i = 0; i < sensors.length; i++) {
      const itrSensor = sensors[i];
      if (itrSensor[type] == itemID) {
        sensor = Object.assign({}, itrSensor);
        break;
      }
    }
  }

  return sensor;
}

function getCoupleByType(state, itemID, type) {
  let couple;

  const couples = state.couples.couples;
  if (type === 'ID') {
    const indexMap = state.couples.indexMap;
    couple = Object.assign({}, couples[indexMap[itemID]]);
  } else {
    for (let i = 0; i < couples.length; i++) {
      const itrCouple = couples[i];
      if (type === 'SensorsID') {
        if (itrCouple['Sensor1'] == itemID || itrCouple['Sensor2'] == itemID) {
          couple = Object.assign({}, itrCouple);
          break;
        }
      } else if (itrCouple[type] == itemID) {
        couple = Object.assign({}, itrCouple);
        break;
      }
    }
  }

  return couple;
}

const getManageItemByType = (state, itemID) => {
  let returnItem;

  switch (state.manage.managementContext) {
    case 'Users':
      returnItem = getUser(state, itemID);
      break;
    case 'Projects':
      returnItem = getProject(state, itemID);
      break;
    case 'Customers':
      returnItem = getCustomer(state, itemID);
      break;
  }

  return returnItem;
};

const getG5DeviceByType = (state, itemId, type) => {
  const devices = state.devcieConfigurations.deviceList;
  const device = devices.find((item) => item[type] == itemId);
  return device;
};

const getUser = (state, userName) => {
  let returnUser;

  const users = state.management.users;

  for (let i = 0; i < users.length; i++) {
    const user = users[i];
    if (user.UserName.toLowerCase().startsWith(userName.toLowerCase())) {
      returnUser = user;
      break;
    }
  }

  return returnUser;
};

const getProject = (state, projectName) => {
  let returnProject;

  const projects = state.leaksList.projectsList.items;

  for (let i = 0; i < projects.length; i++) {
    const project = projects[i];
    if (project.Name.toLowerCase().startsWith(projectName.toLowerCase())) {
      returnProject = project;
      break;
    }
  }

  return returnProject;
};

const getCustomer = (state, customerName) => {
  let returnCustomer;

  const customers = state.leaksList.customers.items;

  for (let i = 0; i < customers.length; i++) {
    const customer = customers[i];
    if (customer.Name.toLowerCase().startsWith(customerName.toLowerCase())) {
      returnCustomer = customer;
      break;
    }
  }

  return returnCustomer;
};

const getSopItemByType = (state, itemID, type) => {
  let sop;

  const sops = state.sops.sops;
  if (type === 'ID') {
    const indexMap = state.sops.indexMap;
    sop = Object.assign({}, sops[indexMap[itemID]]);
  } else {
    for (let i = 0; i < sops.length; i++) {
      const itrSOP = sops[i];
      if (itrSOP[type] == itemID) {
        sop = Object.assign({}, itrSOP);
        break;
      }
    }
  }
  return sop;
};

const getInterferenceByType = (state, itemID, type) => {
  let interference;

  const interferences = state.interferences.interferences;
  if (type === 'ID') {
    const indexMap = state.interferences.indexMap;
    interference = Object.assign({}, interferences[indexMap[itemID]]);
  } else {
    for (let i = 0; i < interferences.length; i++) {
      const itrInterference = interferences[i];
      if (itrInterference[type] == itemID) {
        interference = Object.assign({}, itrInterference);
        break;
      }
    }
  }
  return interference;
};
